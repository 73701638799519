import cn from 'classnames';
import React from 'react';
import ReactImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/scss/image-gallery.scss';
import styles from './AnimationGallery.module.sass';
import GalleryItems from './gallery_items';

const AnimationGallery: React.FC = () => {
  return (
    <div className={styles.animationGallery}>
      <div className={styles.preview}>
        <picture>
          {/* <source
                media="(max-width: 767px)"
                srcSet="/images/content/main/hero-pic-mobile.webp"
              /> */}
          <img
            className={styles.background}
            src="/images/content/animation-teaser/gallery-background.webp"
            alt="teaser"
          />
        </picture>
        <div className={cn('animation-gallery')}>
          <ReactImageGallery items={GalleryItems()} />
        </div>
      </div>
    </div>
  );
};

export default AnimationGallery;
