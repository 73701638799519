import * as React from 'react';
import SvgProps from './SvgProps';

const SvgInfo: React.FunctionComponent<SvgProps> = ({
  width = 24,
  height = 24,
  fill = 'currentColor',
}: SvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill={fill}
    viewBox="0 0 24 24"
  >
    <defs>
      <clipPath id="info_svg__a">
        <path d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
    <g clipPath="url(#info_svg__a)">
      <path d="M11.751 2.006a9.5 9.5 0 0 1 5.58 1.564 11.582 11.582 0 0 1 2.967 2.838 8.632 8.632 0 0 1 1.635 4.3 10.109 10.109 0 0 1-.913 5.721 10.211 10.211 0 0 1-2.185 2.831 10.33 10.33 0 0 1-3.937 2.352 10.186 10.186 0 0 1-4.2.306 8.514 8.514 0 0 1-3.936-1.383 10.979 10.979 0 0 1-4.09-4.8 7.882 7.882 0 0 1-.643-2.84 11.475 11.475 0 0 1 .328-3.737 9.073 9.073 0 0 1 2.016-3.641 10.517 10.517 0 0 1 4.1-2.914 8.573 8.573 0 0 1 3.282-.595m.309 18.218a8.212 8.212 0 0 0 8.214-8.573 8.266 8.266 0 0 0-16.523.468 8.221 8.221 0 0 0 8.309 8.1" />
      <path d="M10.625 7.261a1.23 1.23 0 0 1 1.289-1.263 1.287 1.287 0 0 1 .035 2.571 1.261 1.261 0 0 1-1.324-1.308" />
      <path d="M11.498 16.036c-.415 0-.8-.019-1.19.008a1.135 1.135 0 0 0-.544.18.668.668 0 0 0-.187.789.705.705 0 0 0 .71.442c1.308.01 2.617 0 3.925 0a1.013 1.013 0 0 0 .271-.036.592.592 0 0 0 .482-.429.692.692 0 0 0-.62-.947 2.83 2.83 0 0 0-.364-.012h-.863v-4.723a2.75 2.75 0 0 0-.016-.339c-.072-.566-.298-.771-.867-.779h-1.757a2.272 2.272 0 0 0-.361.049.428.428 0 0 0-.368.359c-.1.523.023.947.688 1.014a5.773 5.773 0 0 0 .821.012c.2-.008.256.059.255.26-.01 1.306-.005 2.617-.006 3.925 0 .067-.006.134-.01.226" />
    </g>
  </svg>
);
export default SvgInfo;
