import * as React from 'react';
import SvgProps from './SvgProps';

const SvgArrowNext: React.FunctionComponent<SvgProps> = ({
  width = 24,
  height = 24,
  fill = 'currentColor',
}: SvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill={fill}
    viewBox="0 0 24 24"
  >
    <defs>
      <clipPath id="arrow-next_svg__a">
        <path d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
    <g clipPath="url(#arrow-next_svg__a)">
      <path d="M14.764 6.403a1.322 1.322 0 0 1 1.868.075l4.27 4.626a1.322 1.322 0 0 1 0 1.793l-4.27 4.626a1.322 1.322 0 1 1-1.942-1.793l2.223-2.408H4.07a1.322 1.322 0 0 1 0-2.643h12.841L14.689 8.27a1.322 1.322 0 0 1 .075-1.867Z" />
    </g>
  </svg>
);
export default SvgArrowNext;
