import AWS from 'aws-sdk';
import cn from 'classnames';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Form from '../Form';
import Icon from '../Icon';
import Image from '../Image';
import Modal from '../Modal';
import Theme from '../Theme';
import styles from './Footer.module.sass';

const socialNavMenu = [
  // {
  //   icon: 'facebook',
  //   url: '/',
  // },
  // {
  //   icon: 'twitter',
  //   url: '/',
  // },
  // {
  //   icon: 'instagram',
  //   url: '/',
  // },
  {
    icon: 'twitter',
    url: 'https://twitter.com/dbearuniverse',
  },
  {
    icon: 'telegram',
    url: 'https://t.me/+V5012iYw9kBhM2I5',
  },
];

interface SocialItem {
  icon: string;
  url: string;
}

const Footer: React.FC = () => {
  // the state for email
  const [email, setEmail] = useState<string>('');

  // the state for modal visible
  const [visible, setVisible] = useState<boolean>(false);

  // the state for subscrition result
  const [message, setMessage] = useState<string>('');

  // the state for loading while in login process
  const [isLoading, setIsLoading] = React.useState(false);

  // the handle submit email
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    // prevent default behavior form
    e.preventDefault();
    const dynamodb = new AWS.DynamoDB.DocumentClient();
    const params = {
      TableName: 'subscriptions-staging',
      Item: {
        email,
        timestamp: Date.now(),
      },
    };
    try {
      if (isLoading) return;
      setIsLoading(true);
      await dynamodb.put(params).promise();
      setMessage('Mailing subscription completed successfully! Thank you.');
    } catch (error: unknown) {
      if (error instanceof Error) {
        console.error('Error saving DynamoDB:', error.message);
        setMessage(
          'Mailing subscription failed! Please try again later, as it may be a temporary issue.'
        );
      }
    } finally {
      setEmail('');
      setIsLoading(false);
      setVisible(true);
    }
  };

  return (
    <>
      <div className={styles.footer}>
        {/* top */}
        <div className={cn('container', styles.top)}>
          <div className={styles.row}>
            {/* column for logo & theme */}
            <div className={styles.col}>
              {/* logo */}
              <Link className={styles.logo} to="/">
                <Image
                  src="/images/logo/logo-dark.svg"
                  srcDark="/images/logo/logo-light.svg"
                  alt="D:Bear"
                />
              </Link>
              {/* theme switch */}
              <div className={styles.box}>
                <Icon name="bulb" width={20} height={20} />
                <span>Dark theme</span>
                <Theme className={styles.theme} />
              </div>
            </div>

            {/* column for nav menu */}
            <div className={styles.col}>
              {/* not use */}
              {/* <div className={styles.menu}>
              {footerNavMenu.map((x: FooterItem, index: number) => (
                <Link className={styles.link} to={x.url} key={index}>
                  {x.title}
                </Link>
              ))}
            </div> */}
            </div>

            {/* column for email */}
            <div className={styles.col}>
              <div className={styles.info}>
                Join Newsletter{' '}
                <span role="img" aria-label="mail">
                  📨
                </span>
              </div>
              <div className={styles.description}>
                Get the latest news from Dbear Universe
              </div>
              <Form
                className={styles.form}
                value={email}
                setValue={setEmail}
                onSubmit={(e: React.FormEvent<HTMLFormElement>) =>
                  handleSubmit(e)
                }
                isLoading={isLoading}
                placeholder="Enter your email"
                type="email"
                name="email"
                icon="arrow-forward"
              />
            </div>
          </div>
        </div>
        {/* bottom */}
        <div className={styles.bottom}>
          <div className={cn('container', styles.container)}>
            {/* copyright */}
            <div className={styles.copyright}>
              © All right reserved by Dbear Universe. Inc
            </div>

            {/* social buttons */}
            <div className={styles.socials}>
              {socialNavMenu.map((x: SocialItem, index: number) => (
                <a
                  key={index}
                  className={styles.social}
                  href={x.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Icon name={x.icon} width={20} height={20} />
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Modal
        visible={visible}
        onClose={() => {
          setVisible(false);
          setMessage('');
        }}
      >
        <div>
          <div className={cn('h3', styles.modalTitle)}>
            Thank you for subscribing!
          </div>
          <div className={styles.modalInfo}>{message}</div>
        </div>
      </Modal>
    </>
  );
};

export default Footer;
