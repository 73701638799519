import cn from 'classnames';
import styles from './SlickCard.module.sass';
import Image from '../../../../components/Image';

interface SlickCardProps {
  title: string;
  info: string;
  src: string;
  srcSet: string;
  srcDark: string;
  srcSetDark: string;
  alt: string;
}

const SlickCard: React.FC<SlickCardProps> = ({
  title,
  info,
  src,
  srcSet,
  srcDark,
  srcSetDark,
  alt,
}) => {
  return (
    <div className={styles.slickCard}>
      <div className={cn('section', styles.section)}>
        <div className={cn('container', styles.container)}>
          <div className={styles.wrap}>
            <div className={styles.title}>{title}</div>
            <div className={styles.info}>{info}</div>
            <div className={styles.infoBox}>Coming Soon</div>
          </div>
          <div className={styles.preview}>
            <Image
              srcSet={`${srcSet} 2x`}
              srcSetDark={`${srcSetDark} 2x`}
              src={src}
              srcDark={srcDark}
              alt={alt}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SlickCard;
