import SvgAddUser from './icons/SvgAddUser';
import SvgArrowBackward from './icons/SvgArrowBackward';
import SvgArrowForward from './icons/SvgArrowForward';
import SvgArrowNext from './icons/SvgArrowNext';
import SvgArrowPrev from './icons/SvgArrowPrev';
import SvgBeakerLine from './icons/SvgBeakerLine';
import SvgBulb from './icons/SvgBulb';
import SvgCheck from './icons/SvgCheck';
import SvgChevronDown from './icons/SvgChevronDown';
import SvgClose from './icons/SvgClose';
import SvgControllerLine from './icons/SvgControllerLine';
import SvgCup from './icons/SvgCup';
import SvgFacebook from './icons/SvgFacebook';
import SvgGlobe from './icons/SvgGlobe';
import SvgHexagon from './icons/SvgHexagon';
import SvgInfo from './icons/SvgInfo';
import SvgInstagram from './icons/SvgInstagram';
import SvgLock from './icons/SvgLock';
import SvgLogout from './icons/SvgLogout';
import SvgMail from './icons/SvgMail';
import SvgNFT from './icons/SvgNFT';
import SvgPdf from './icons/SvgPdf';
import SvgProps from './icons/SvgProps';
import SvgSettings from './icons/SvgSettings';
import SvgShoppingCartLine from './icons/SvgShoppingCartLine';
import SvgTelegram from './icons/SvgTelegram';
import SvgTwitter from './icons/SvgTwitter';
import SvgUserLine from './icons/SvgUserLine';
import SvgYoutube from './icons/SvgYoutube';

interface IconProps {
  name: string;
  width?: number;
  height?: number;
  fill?: string;
}

const Icon: React.FunctionComponent<IconProps> = ({
  name,
  width = 24,
  height = 24,
  fill = 'currentColor',
}: IconProps) => {
  const prop: SvgProps = {
    width: width,
    height: height,
    fill: fill,
  };

  switch (name) {
    case 'add-user':
      return <SvgAddUser {...prop} />;
    case 'arrow-backward':
      return <SvgArrowBackward {...prop} />;
    case 'arrow-forward':
      return <SvgArrowForward {...prop} />;
    case 'arrow-next':
      return <SvgArrowNext {...prop} />;
    case 'arrow-prev':
      return <SvgArrowPrev {...prop} />;
    case 'beaker-line':
      return <SvgBeakerLine {...prop} />;
    case 'bulb':
      return <SvgBulb {...prop} />;
    case 'check':
      return <SvgCheck {...prop} />;
    case 'chevron-down':
      return <SvgChevronDown {...prop} />;
    case 'close':
      return <SvgClose {...prop} />;
    case 'controller-line':
      return <SvgControllerLine {...prop} />;
    case 'cup':
      return <SvgCup {...prop} />;
    case 'facebook':
      return <SvgFacebook {...prop} />;
    case 'globe':
      return <SvgGlobe {...prop} />;
    case 'hexagon':
      return <SvgHexagon {...prop} />;
    case 'info':
      return <SvgInfo {...prop} />;
    case 'instagram':
      return <SvgInstagram {...prop} />;
    case 'lock':
      return <SvgLock {...prop} />;
    case 'logout':
      return <SvgLogout {...prop} />;
    case 'mail':
      return <SvgMail {...prop} />;
    case 'nft':
      return <SvgNFT {...prop} />;
    case 'pdf':
      return <SvgPdf {...prop} />;
    case 'settings':
      return <SvgSettings {...prop} />;
    case 'shopping-cart-line':
      return <SvgShoppingCartLine {...prop} />;
    case 'telegram':
      return <SvgTelegram {...prop} />;
    case 'twitter':
      return <SvgTwitter {...prop} />;
    case 'user-line':
      return <SvgUserLine {...prop} />;
    case 'youtube':
      return <SvgYoutube {...prop} />;
    default:
      return null;
  }
};

export default Icon;
