import * as React from 'react';
import SvgProps from './SvgProps';

const SvgHexagon: React.FunctionComponent<SvgProps> = ({
  width = 24,
  height = 24,
  fill = 'currentColor',
}: SvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill={fill}
    viewBox="0 0 22 24"
  >
    <mask
      id="Clip_path_group_svg__a"
      width={width}
      height={height}
      x={0}
      y={0}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: 'alpha',
      }}
    >
      <path d="M8.619.64a4.753 4.753 0 0 1 4.763 0l6.237 3.611A4.77 4.77 0 0 1 22 8.39v7.223a4.789 4.789 0 0 1-2.381 4.137l-6.237 3.611a4.752 4.752 0 0 1-4.763 0L2.38 19.749A4.788 4.788 0 0 1 0 15.611V8.389A4.788 4.788 0 0 1 2.381 4.25L8.62.64Z" />
    </mask>
    <g mask="url(#Clip_path_group_svg__a)">
      <path d="M0 0h22.016v23.976H0z" />
    </g>
  </svg>
);
export default SvgHexagon;
