// check email format
export const checkEmail = (email: string): string => {
  if (email === '') return '';
  const emailRegex =
    /([\w-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
  if (!emailRegex.test(email)) {
    return 'The email format is incorrect.';
  } else {
    return '';
  }
};

// check password policy
export const checkPassword = (password: string): string => {
  if (password === '') return '';
  const passwordRegex = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,25}$/;
  return !passwordRegex.test(password)
    ? 'Password must contain at least 8 characters, including letters, numbers and special characters.'
    : '';
};

// heck confirm password
export const checkConfirm = (password: string, confirm: string): string => {
  if (confirm === '') return '';
  return password !== confirm ? 'Passwords do not match.' : '';
};
