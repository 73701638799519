import cn from 'classnames';
import { useState } from 'react';
import Icon from '../../../components/Icon';
import styles from './MainLogin.module.sass';
import Panel from './Panel';
import PanelItem from './PanelItem';
import { useUserInfoContext } from '../../../libs/userInfoContext';
import Character, { characterIdToName } from '../../../components/Character';
import Badge from './badge';

interface MainLoginProps {
  onClickLearning?: () => void;
  onClickShopping?: () => void;
  onClickPlaying?: () => void;
}

const MainLogin: React.FC<MainLoginProps> = ({
  onClickLearning,
  onClickShopping,
  onClickPlaying,
}) => {
  type Achievement = {
    medal: string;
    date: string;
    title: string;
  };

  const menus = [
    {
      id: 1,
      title: 'Congratulations!',
      description: 'You can see your character now!',
      icon: 'avatar',
    },
    {
      id: 2,
      title: 'Learning',
      description: 'Variety of education',
      icon: 'beaker-line',
    },
    {
      id: 3,
      title: 'Shopping',
      description: 'infants & children',
      icon: 'shopping-cart-line',
    },
    {
      id: 4,
      title: 'Playing',
      description: 'Educational games',
      icon: 'controller-line',
    },
  ];

  // the state for user info
  const { userInfo } = useUserInfoContext();

  // the state for character info visible
  const [visible, setVisible] = useState<boolean>(true);

  // the toggle visible character info
  const toggleVisible = () => {
    setVisible(!visible);
  };

  const getArchives = (): Achievement[] => {
    // JavaScript의 Date 객체로 변환
    const dateObject = new Date(userInfo.joinDate ?? 0);
    // 년/월/일만 추출
    const year = dateObject.getFullYear();
    const month = dateObject.getMonth() + 1; // 월은 0부터 시작하기 때문에 1을 더해줍니다.
    const day = dateObject.getDate();
    // 년.월.일 형식으로 출력
    const formattedDate = `${year}.${month < 10 ? '0' + month : month}.${
      day < 10 ? '0' + day : day
    }`;
    const achievements: Achievement[] = [
      { medal: '1', date: formattedDate, title: 'First Signup' },
      { medal: '2', date: '', title: '5-Course Complete' },
      { medal: '2', date: '', title: '10-Course Master' },
      { medal: '2', date: '', title: '20-Course Expert' },
      { medal: '2', date: '', title: '3-Day Streak' },
      { medal: '2', date: '', title: 'Monthly Excellence' },
      { medal: '2', date: '', title: 'Annual Champion' },
      { medal: '2', date: '', title: 'Subject Specialist' },
    ];
    return achievements;
  };

  // the item click callback
  const onPanelItemClick = (id: number) => {
    switch (id) {
      case 1:
        toggleVisible();
        break;
      case 2:
        onClickLearning && onClickLearning();
        break;
      case 3:
        onClickShopping && onClickShopping();
        break;
      case 4:
        onClickPlaying && onClickPlaying();
        break;
    }
  };

  return (
    <div className={styles.main}>
      <div className={cn('section', styles.section)}>
        <div
          className={cn('container', styles.container, {
            [styles.showCharacter]: visible,
          })}
        >
          <div className={styles.preview}>
            <picture>
              <source
                media="(max-width: 767px)"
                srcSet="/images/content/main/hero-pic-mobile.webp"
              />
              <img
                className={styles.background}
                src="/images/content/main/hero-pic.webp"
                alt="Hero"
              />
            </picture>
            <div className={styles.wrap}>
              <div className={styles.title}>
                DBEAR
                <br />
                UNIVERSE
              </div>
            </div>
            <img
              className={styles.characters}
              src="/images/content/main/characters.webp"
              alt="Characters"
            />
            {/*  */}
            <div className={styles.characterContainer}>
              <div className={styles.leftSide}>
                <div className={styles.leftTop}>
                  <img
                    src={Character(userInfo.characterId)}
                    alt={characterIdToName(userInfo.characterId)}
                  />
                </div>
                <div className={styles.leftBottom}>{userInfo.email}</div>
              </div>
              <div className={styles.rightSide}>
                <div className={styles.rightTop}>
                  <div className={styles.infoTitle}>Badges</div>
                  {getArchives().map((achievement, index) => (
                    <div className={styles.achievement} key={index}>
                      <img src={Badge(achievement.medal)} alt={''} />
                      <div className={styles.achievementDate}>
                        {achievement.date}
                      </div>
                      <div className={styles.achievementTitle}>
                        {achievement.title}
                      </div>
                    </div>
                  ))}
                </div>
                <div className={styles.rightMiddle}>
                  <div className={styles.infoTitle}>NFTs</div>
                  <div className={styles.infoBox}>Coming Soon</div>
                </div>
                <div className={styles.rightBottom}>
                  <div className={styles.infoTitle}>Friends</div>
                  <div className={styles.infoBox}>Coming Soon</div>
                </div>
              </div>
              <button className={styles.close} onClick={toggleVisible}>
                <Icon name="close" width={24} height={24} />
              </button>
            </div>
            {/*  */}
          </div>
          <Panel className={styles.panel} classBody={styles.body}>
            <div className={styles.row}>
              {menus.map((x, index) => (
                <PanelItem
                  key={index}
                  {...x}
                  onClick={() => onPanelItemClick(x.id)}
                />
              ))}
            </div>
          </Panel>
        </div>
      </div>
    </div>
  );
};

export default MainLogin;
