import cn from 'classnames';
import React, { ChangeEvent, FormEvent } from 'react';
import Icon from '../Icon';
import styles from './Form.module.sass';

interface FormProps {
  className?: string;
  big?: boolean;
  onSubmit: (event: FormEvent<HTMLFormElement>) => void;
  placeholder?: string;
  value: string;
  setValue: (value: string) => void;
  type?: string;
  name?: string;
  icon: string;
  isLoading?: boolean;
}

const Form: React.FC<FormProps> = ({
  className,
  big = false,
  onSubmit,
  placeholder,
  value,
  setValue,
  type = 'text',
  name,
  icon,
  isLoading,
}) => {
  return (
    <form
      className={cn(className, styles.form, {
        [styles.big]: big,
      })}
      onSubmit={onSubmit}
    >
      <input
        className={styles.input}
        type={type}
        value={value}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          setValue(e.target.value)
        }
        name={name}
        placeholder={placeholder}
        required
      />
      <button className={styles.btn}>
        {isLoading && <div className={styles.loader} />}
        {!isLoading && <Icon name={icon} width={20} height={20} />}
      </button>
    </form>
  );
};

export default Form;
