import cn from 'classnames';
import { use100vh } from 'react-div-100vh';
import styles from './Characters.module.sass';
import Carousel from './Slider/Carousel';

const Characters = () => {
  // the height of window
  const heightWindow = use100vh();

  return (
    <div
      className={styles.characters}
      style={{ minHeight: heightWindow ? `${heightWindow}px` : undefined }}
    >
      <div className={cn('section', styles.section)}>
        <div className={cn('container', styles.container)}>
          <div className={styles.inner}>
            {/* not use */}
            {/* <Link className={styles.topLink} to="/">
              <Icon name="close" width={32} height={32} />
            </Link> */}
            {/* container */}

            <div className={styles.wrap}>
              <div className={styles.content}>
                <div className={styles.title}>
                  Welcome To The Dbear Universe.
                </div>
                <div className={styles.subtitle}>
                  Dbear UNIVERSE Aims To Complete 'Character-Based Lifestyle
                  Commerce' For MZ Generation.
                </div>
                <div className={styles.info}>
                  If you become a member of the Dbear Universe, you will be
                  issued a Dbear Member's NFT Card, and you will be able to
                  access various lifestyle platforms in the Dbear ecosystem with
                  only the possession of the NFT, and receive special consumer
                  rewards.
                </div>
              </div>
            </div>
            <div className={styles.gallery}>
              <Carousel />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Characters;
