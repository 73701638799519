import { Amplify } from 'aws-amplify';
import AWS from 'aws-sdk';
import { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import awsExports from './aws-exports';
import { CombinedProvider } from './combinedProvider';
import Page from './components/Page';
import Join from './screens/Auth/Join';
import Login from './screens/Auth/Login';
import Reset from './screens/Auth/Reset';
import Home from './screens/Home';
import './styles/app.sass';

// initialize Amplify
Amplify.configure(awsExports);

function App() {
  // the aws configure update
  const updateAwsConfig = async () => {
    const region: string | undefined = process.env.REACT_APP_AWS_REGION;
    const accessKeyId: string | undefined =
      process.env.REACT_APP_AWS_ACCESS_KEY_ID;
    const secretAccessKey: string | undefined =
      process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;
    if (!region || !accessKeyId || !secretAccessKey) {
      return console.error('There is no aws environment configuration.');
    }
    AWS.config.update({
      region: region,
      credentials: {
        accessKeyId: accessKeyId,
        secretAccessKey: secretAccessKey,
      },
    });
  };

  // update aws configure
  useEffect(() => {
    async function checkAuthState() {
      try {
        updateAwsConfig();
      } catch (error: unknown) {
        if (error instanceof Error) console.error(error.toString());
      }
    }
    checkAuthState();
  }, []);

  return (
    <CombinedProvider>
      <Routes>
        <Route path="/">
          <Route
            index
            element={
              <Page>
                <Home />
              </Page>
            }
          />
        </Route>
        <Route path="login" element={<Login />} />
        <Route path="join" element={<Join />} />
        <Route path="reset" element={<Reset />} />
      </Routes>
    </CombinedProvider>
  );
}

export default App;
