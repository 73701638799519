import * as React from 'react';
import SvgProps from './SvgProps';

const SvgTwitter: React.FunctionComponent<SvgProps> = ({
  width = 24,
  height = 24,
  fill = 'currentColor',
}: SvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill={fill}
    viewBox="0 0 24 24"
  >
    <defs>
      <clipPath id="twitter_svg__a">
        <path d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
    <g clipPath="url(#twitter_svg__a)">
      <path
        fillRule="evenodd"
        d="M3.116 14A2.1 2.1 0 0 0 1.4 17.316C2.871 19.36 5.535 21 9.984 21c6.894 0 12.428-5.578 11.653-12.169l1.135-2.254a2.1 2.1 0 0 0-2.306-3l-1.5.3a7.534 7.534 0 0 0-1.16-.477 7.729 7.729 0 0 0-2.283-.4 5.757 5.757 0 0 0-3.515 1.056 5.062 5.062 0 0 0-1.751 2.453 6.6 6.6 0 0 0-.279 1.149 10.217 10.217 0 0 1-1.615-.667 8.734 8.734 0 0 1-2.744-2.105 2.121 2.121 0 0 0-3.582.435 8.433 8.433 0 0 0 .163 6.692A9.834 9.834 0 0 0 3.344 14Zm6.868 5c-3.891 0-5.9-1.4-6.943-2.846A.1.1 0 0 1 3.122 16a10.151 10.151 0 0 0 4.663-.885.1.1 0 0 0-.024-.179c-3.34-1.115-5.238-5.837-3.89-8.79a.1.1 0 0 1 .169-.018A11.493 11.493 0 0 0 11.876 10a.1.1 0 0 0 .1-.119C11.86 9.121 11.409 5 15.523 5a5.545 5.545 0 0 1 2.983.963.106.106 0 0 0 .092.026l2.262-.449a.1.1 0 0 1 .11.143l-1.4 2.787a.1.1 0 0 0-.009.063C20.541 14.02 16.015 19 9.984 19Z"
      />
    </g>
  </svg>
);
export default SvgTwitter;
