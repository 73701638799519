import { createContext, useContext } from 'react';
import { UserInfo } from './userInfoDao';

// 사용자 정보 타입 정의
type UserInfoContextProps = {
  // 사용자 정보
  userInfo: UserInfo;
  // 인증 상태 변경 함수
  setUserInfo: (state: UserInfo) => void;
};

// UserInfoContextProps 혹은 null을 받는 컨텍스트 생성
export const UserInfoContext = createContext<UserInfoContextProps | null>(null);

// UserInfoContext 를 컴포넌트에서 사용할 수 있도록 커스텀 훅 생성
export function useUserInfoContext() {
  const context = useContext(UserInfoContext);

  if (!context) {
    throw new Error(
      'useUserInfoContext must be used within an AppContext Provider'
    );
  }

  return context;
}

export type { UserInfoContextProps };
