import React from 'react';
import cn from 'classnames';
import styles from './Loader.module.sass';

interface LoaderProps {
  className?: string;
  white?: boolean;
}

const Loader: React.FC<LoaderProps> = ({ className, white }) => {
  return (
    <div
      className={cn(styles.loader, className, {
        [styles.loaderWhite]: white,
      })}
    ></div>
  );
};

export default Loader;
