import cn from 'classnames';
import styles from './Main.module.sass';
import Panel from './Panel';
import PanelItem from './PanelItem';

interface MainProps {
  onClickGetCharacter?: () => void;
  onClickLearning?: () => void;
  onClickShopping?: () => void;
  onClickPlaying?: () => void;
}

const Main: React.FC<MainProps> = ({
  onClickGetCharacter,
  onClickLearning,
  onClickShopping,
  onClickPlaying,
}) => {
  const menus = [
    {
      id: 1,
      title: 'Get your character',
      description: 'Go sign up for membership',
      icon: 'user-line',
    },
    {
      id: 2,
      title: 'Learning',
      description: 'Variety of education',
      icon: 'beaker-line',
    },
    {
      id: 3,
      title: 'Shopping',
      description: 'infants & children',
      icon: 'shopping-cart-line',
    },
    {
      id: 4,
      title: 'Playing',
      description: 'Educational games',
      icon: 'controller-line',
    },
  ];

  // the item click callback
  const onPanelItemClick = (id: number) => {
    switch (id) {
      case 1:
        onClickGetCharacter && onClickGetCharacter();
        break;
      case 2:
        onClickLearning && onClickLearning();
        break;
      case 3:
        onClickShopping && onClickShopping();
        break;
      case 4:
        onClickPlaying && onClickPlaying();
        break;
    }
  };

  return (
    <div className={styles.main}>
      <div className={cn('section', styles.section)}>
        <div className={cn('container', styles.container)}>
          <div className={styles.preview}>
            <picture>
              <source
                media="(max-width: 767px)"
                srcSet="/images/content/main/hero-pic-mobile.webp"
              />
              <img
                className={styles.background}
                src="/images/content/main/hero-pic.webp"
                alt="Hero"
              />
            </picture>
            <div className={styles.wrap}>
              <div className={styles.title}>
                DBEAR
                <br />
                UNIVERSE
              </div>
            </div>
            <img
              className={styles.characters}
              src="/images/content/main/characters.webp"
              alt="Characters"
            />
          </div>
          <Panel className={styles.panel} classBody={styles.body}>
            <div className={styles.row}>
              {menus.map((x, index) => (
                <PanelItem
                  key={index}
                  {...x}
                  onClick={() => onPanelItemClick(x.id)}
                />
              ))}
            </div>
          </Panel>
        </div>
      </div>
    </div>
  );
};

export default Main;
