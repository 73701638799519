import * as React from 'react';
import SvgProps from './SvgProps';

const SvgMail: React.FunctionComponent<SvgProps> = ({
  width = 24,
  height = 24,
  fill = 'currentColor',
}: SvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill={fill}
    viewBox="0 0 24 24"
  >
    <defs>
      <clipPath id="mail_svg__a">
        <path d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
    <g clipPath="url(#mail_svg__a)">
      <path d="M19 20H5a3 3 0 0 1-3-3V7a3 3 0 0 1 3-3h14a3 3 0 0 1 3 3v10a3 3 0 0 1-3 3ZM4 8.868V17a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V8.868l-6.336 4.225a3 3 0 0 1-3.328 0ZM5 6a1 1 0 0 0-.887.54l7.333 4.889a1 1 0 0 0 1.109 0l7.333-4.889A1 1 0 0 0 19 6Z" />
    </g>
  </svg>
);

export default SvgMail;
