import * as React from 'react';
import SvgProps from './SvgProps';

const SvgGlobe: React.FunctionComponent<SvgProps> = ({
  width = 24,
  height = 24,
  fill = 'currentColor',
}: SvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill={fill}
    viewBox="0 0 24 24"
  >
    <defs>
      <clipPath id="globe_svg__a">
        <path d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
    <g clipPath="url(#globe_svg__a)">
      <path
        fillRule="evenodd"
        d="M12 20a8 8 0 1 0-8-8 8 8 0 0 0 8 8Zm0 2A10 10 0 1 0 2 12a10 10 0 0 0 10 10Z"
      />
      <path
        fillRule="evenodd"
        d="M13.747 18.177A14.248 14.248 0 0 0 15 12a14.248 14.248 0 0 0-1.253-6.177C12.948 4.226 12.215 4 12 4s-.948.226-1.747 1.823A14.248 14.248 0 0 0 9 12a14.247 14.247 0 0 0 1.253 6.177C11.052 19.774 11.785 20 12 20s.948-.226 1.747-1.823ZM12 22c2.761 0 5-4.477 5-10S14.761 2 12 2 7 6.477 7 12s2.239 10 5 10Z"
      />
      <path d="M21.951 13q.049-.493.049-1t-.049-1H2.049Q2 11.493 2 12t.049 1Z" />
    </g>
  </svg>
);
export default SvgGlobe;
