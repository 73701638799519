import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../libs/authContext';
import AnimationTeaser from './AnimationTeaser';
import Characters from './Characters';
import DbcInformation from './DbcInformation';
import Learning from './Learning';
import Main from './Main';
import MainLogin from './MainLogin';
import Officers from './Officers';
import Playing from './Playing';
import Shoppoing from './Shopping';
import Whitepaper from './Whitepaper';
import Work from './Work';

const Home = () => {
  // the navigate hook
  const navigate = useNavigate();

  // the state for login state
  const { isAuthenticated } = useAuthContext();

  // the ref learning for scroll to content
  const learningRef = useRef<HTMLDivElement>(null);

  // the ref shopping for scroll to content
  const shoppingRef = useRef<HTMLDivElement>(null);

  // the ref playing for scroll to content
  const playingRef = useRef<HTMLDivElement>(null);

  // the handle get character clicked
  const onClickGetCharacter = () => {
    if (isAuthenticated) {
      // TODO - add later
    } else {
      navigate('/join');
    }
  };

  // the handle scroll to learning
  const onClickLearning = () => {
    // learningRef.current?.scrollIntoView({ behavior: 'smooth' });
    window.open('https://master.djigl9878gj6z.amplifyapp.com/', '_blank');
  };

  // the handle scroll to shopping
  const onClickShopping = () => {
    shoppingRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  // the handle scroll to playing
  const onClickPlaying = () => {
    playingRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <>
      {isAuthenticated ? (
        <MainLogin
          onClickLearning={onClickLearning}
          onClickShopping={onClickShopping}
          onClickPlaying={onClickPlaying}
        />
      ) : (
        <Main
          onClickGetCharacter={onClickGetCharacter}
          onClickLearning={onClickLearning}
          onClickShopping={onClickShopping}
          onClickPlaying={onClickPlaying}
        />
      )}
      <AnimationTeaser />
      <Characters />
      <Work />
      <Learning ref={learningRef} />
      <Shoppoing ref={shoppingRef} />
      <Playing ref={playingRef} />
      <Officers />
      <DbcInformation />
      <Whitepaper />
    </>
  );
};

export default Home;
