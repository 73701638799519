import { Auth } from 'aws-amplify';
import cn from 'classnames';
import React, { useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { NavLink, useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../../libs/authContext';
import { useUserInfoContext } from '../../../libs/userInfoContext';
import Icon from '../../Icon';
import styles from './User.module.sass';

interface MenuItem {
  type: string;
  url: string;
  icon: string;
  title: string;
}

interface Item {
  menu: MenuItem[];
}

interface UserProps {
  className?: string;
  items: Item[];
}

const User: React.FC<UserProps> = ({ className, items }) => {
  // the navigate hook
  const navigate = useNavigate();

  // the state for visible nav menu
  const [visible, setVisible] = useState(false);

  // the state for set login state
  const { setIsAuthenticated } = useAuthContext();

  // the state for user info
  const { userInfo } = useUserInfoContext();

  // the handle menu clicked
  const handleMenuClick = async (x: MenuItem) => {
    // logout clicked
    if (x.title === 'Logout') {
      try {
        await Auth.signOut();
        setIsAuthenticated(false);
        navigate('/');
      } catch (error: unknown) {
        if (error instanceof Error) console.error(error);
        // ADD LATER - show error message
      }
    }
  };

  // the handle get image path by character id
  const getImagePath = (): string => {
    switch (userInfo.characterId) {
      case 1:
        return '/images/content/header/avatar-1-hani.webp';
      case 2:
        return '/images/content/header/avatar-2-toni.webp';
      case 3:
        return '/images/content/header/avatar-3-seeni.webp';
      case 4:
        return '/images/content/header/avatar-4-gomi.webp';
      case 5:
        return '/images/content/header/avatar-5-roni.webp';
      case 6:
        return '/images/content/header/avatar-6-dani.webp';
      case 7:
        return '/images/content/header/avatar-7-pani.webp';
      default:
        return '/images/content/header/avatar-default.webp';
    }
  };

  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      <div
        className={cn(styles.user, className, {
          [styles.active]: visible,
        })}
      >
        {/* image button */}
        <button className={styles.head} onClick={() => setVisible(!visible)}>
          <img src={getImagePath()} alt="Avatar" />
        </button>

        {/* menu body */}
        <div className={styles.body}>
          <div className={styles.group}>
            {items.map((item: Item, index: number) => (
              <div className={styles.menu} key={index}>
                {item.menu.map((x: MenuItem, index: number) =>
                  x.type === 'button' ? (
                    <button
                      key={index}
                      className={styles.item}
                      onClick={() => handleMenuClick(x)}
                    >
                      <div className={styles.icon}>
                        <Icon name={x.icon} width={24} height={24} />
                      </div>
                      <div className={styles.text}>{x.title}</div>
                    </button>
                  ) : (
                    <NavLink
                      className={styles.item}
                      to={x.url}
                      onClick={() => setVisible(!visible)}
                      key={index}
                    >
                      <div className={styles.icon}>
                        <Icon name={x.icon} width={24} height={24} />
                      </div>
                      <div className={styles.text}>{x.title}</div>
                    </NavLink>
                  )
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default User;
