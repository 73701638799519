import cn from 'classnames';
import { forwardRef } from 'react';
import Slider from 'react-slick';
import Icon from '../../../components/Icon';
import SlickArrow from '../../../components/SlickArrow/SlickArrow';
import Item from './Item';
import styles from './Shopping.module.sass';
import ShppingItems from './shopping_items';

const Shoppoing = (props, ref) => {
  // the shopping items
  const shoppingItems = ShppingItems();

  // slick settings
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    nextArrow: (
      <SlickArrow>
        <Icon name="arrow-next" width={24} height={24} />
      </SlickArrow>
    ),
    prevArrow: (
      <SlickArrow>
        <Icon name="arrow-prev" width={24} height={24} />
      </SlickArrow>
    ),
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 100000,
        settings: 'unslick',
      },
    ],
  };

  return (
    <div ref={ref} className={styles.shopping}>
      <div className={cn('section', styles.section)}>
        <div className={cn('container', styles.container)}>
          <div className={styles.inner}>
            <div className={styles.head}>
              <h2 className={cn('h2', styles.title)}>Shopping</h2>
              <div className={styles.subtitle}>
                Comprehensive products for infants and children
              </div>
              <div className={cn('info', styles.info)}>
                Dbear Mall World A variety of products at special prices.
              </div>
            </div>
            <div>
              <Slider
                className={cn('shopping-slider', styles.slider)}
                {...settings}
              >
                {shoppingItems.map((x, index) => (
                  <Item className={styles.item} item={x} key={index} />
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default forwardRef(Shoppoing);
