import AnimationGallery from './AnimationGallery';
import AnimationPlayer from './AnimationPlayer';
import styles from './AnimationTeaser.module.sass';

const AnimationTeaser: React.FC = () => {
  return (
    <div className={styles.animationTeaser}>
      <div className={styles.container}>
        <AnimationPlayer />
        <div className={styles.subtitle}>Concept Sketch</div>
        <AnimationGallery />
      </div>
    </div>
  );
};

export default AnimationTeaser;
