import * as React from 'react';
import SvgProps from './SvgProps';

const SvgArrowPrev: React.FunctionComponent<SvgProps> = ({
  width = 24,
  height = 24,
  fill = 'currentColor',
}: SvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill={fill}
    viewBox="0 0 24 24"
  >
    <defs>
      <clipPath id="arrow-prev_svg__a">
        <path d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
    <g clipPath="url(#arrow-prev_svg__a)">
      <path d="M9.236 17.597a1.322 1.322 0 0 1-1.868-.075l-4.27-4.626a1.322 1.322 0 0 1 0-1.793l4.27-4.626A1.322 1.322 0 1 1 9.31 8.27l-2.223 2.408H19.93a1.322 1.322 0 0 1 0 2.643H7.089l2.222 2.409a1.322 1.322 0 0 1-.075 1.867Z" />
    </g>
  </svg>
);
export default SvgArrowPrev;
