import cn from 'classnames';
import { ForwardedRef, forwardRef } from 'react';
import styles from './Playing.module.sass';
import SlickCard from './SlickCard';

const cardItems = [
  {
    title: 'DALGOMI, the character based educational game',
    info: "Dbear's special game universe unfolds with differentiated character games.",
    src: '/images/content/playing/playing-pic-1.webp',
    srcSet: '/images/content/playing/playing-pic-1@2x.webp',
    srcDark: '/images/content/playing/playing-pic-1.webp',
    srcSetDark: '/images/content/playing/playing-pic-1@2x.webp',
    alt: 'Playing',
  },
];

const Playing = (props: {}, ref: ForwardedRef<HTMLDivElement>) => {
  // 슬라이더 사용시 주석 해제
  // const settings = {
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   fade: true,
  //   cssEase: 'cubic-bezier(0.7, 0, 0.3, 1)',
  //   nextArrow: (
  //     <SlickArrow>
  //       <Icon name="arrow-next" width={24} height={24} />
  //     </SlickArrow>
  //   ),
  //   prevArrow: (
  //     <SlickArrow>
  //       <Icon name="arrow-prev" width={24} height={24} />
  //     </SlickArrow>
  //   ),
  // };

  return (
    <div ref={ref} className={styles.playing}>
      <div className={styles.container}>
        {/* wrap title */}
        <div className={styles.wrap}>
          <h2 className={cn('h2', styles.title)}>Playing</h2>
          <div className={styles.subtitle}>
            Educational games, metaverse, AR/VR
          </div>
          <div className={styles.info}>
            The fun of Dbear Playing World We invite you to the world of
            exciting games.
          </div>
        </div>
        {/* slider body */}
        {/* 슬라이더 사용시 주석 해제 */}
        {/* <Slider className="playing-slider" {...settings}> */}
        {cardItems.map((x, index) => (
          <SlickCard
            key={index}
            title={x.title}
            info={x.info}
            src={x.src}
            srcSet={x.srcSet}
            srcDark={x.srcDark}
            srcSetDark={x.srcSetDark}
            alt={x.alt}
          />
        ))}
        {/* </Slider> */}
      </div>
    </div>
  );
};

export default forwardRef<HTMLDivElement>(Playing);
