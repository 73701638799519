import * as React from 'react';
import SvgProps from './SvgProps';

const SvgCup: React.FunctionComponent<SvgProps> = ({
  width = 24,
  height = 24,
  fill = 'currentColor',
}: SvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill={fill}
    viewBox="0 0 24 24"
  >
    <path
      fillRule="evenodd"
      d="M16 5H8a1 1 0 0 0-1 1v4a5 5 0 0 0 10 0V6a1 1 0 0 0-1-1ZM8 3a3 3 0 0 0-3 3v4a7 7 0 1 0 14 0V6a3 3 0 0 0-3-3H8Z"
      clipRule="evenodd"
    />
    <path
      fillRule="evenodd"
      d="M1 8a3 3 0 0 1 3-3h3v8H5a4 4 0 0 1-4-4V8Zm3-1h1v4a2 2 0 0 1-2-2V8a1 1 0 0 1 1-1ZM23 8a3 3 0 0 0-3-3h-3v8h2a4 4 0 0 0 4-4V8Zm-3-1h-1v4a2 2 0 0 0 2-2V8a1 1 0 0 0-1-1Z"
      clipRule="evenodd"
    />
    <path d="M12 15a1 1 0 0 0-1 1v3H9a1 1 0 1 0 0 2h6a1 1 0 1 0 0-2h-2v-3a1 1 0 0 0-1-1Z" />
  </svg>
);
export default SvgCup;
