import hani from './characters/character-1-hani.svg';
import toni from './characters/character-2-toni.svg';
import seeni from './characters/character-3-seeni.svg';
import goni from './characters/character-4-gomi.svg';
import roni from './characters/character-5-roni.svg';
import dani from './characters/character-6-dani.svg';
import pani from './characters/character-7-pani.svg';

export const CharacterId = {
  1: 'hani',
  2: 'toni',
  3: 'seeni',
  4: 'gomi',
  5: 'roni',
  6: 'dani',
  7: 'pani',
};

export const characterIdToName = (id) => {
  switch (id) {
    case 1:
      return 'hani';
    case 2:
      return 'toni';
    case 3:
      return 'seeni';
    case 4:
      return 'gomi';
    case 5:
      return 'roni';
    case 6:
      return 'dani';
    case 7:
      return 'pani';
    default:
      return '';
  }
};

const Character = (id) => {
  switch (id) {
    case 1:
      return hani;
    case 2:
      return toni;
    case 3:
      return seeni;
    case 4:
      return goni;
    case 5:
      return roni;
    case 6:
      return dani;
    case 7:
      return pani;
    default:
      return null;
  }
};

export default Character;
