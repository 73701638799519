import cn from 'classnames';
import { ForwardedRef, forwardRef } from 'react';
import Image from '../../../components/Image';
import styles from './Learning.module.sass';

const gallery = [
  {
    src: '/images/content/learning/learning-pic-1.webp',
    srcSet: '/images/content/learning/learning-pic-1@2x.webp',
    srcDark: '/images/content/learning/learning-pic-dark-1.webp',
    srcSetDark: '/images/content/learning/learning-pic-dark-1@2x.webp',
  },
  /*
  {
    src: '/images/content/learning/learning-pic-2.webp',
    srcSet: '/images/content/learning/learning-pic-2@2x.webp',
    srcDark: '/images/content/learning/learning-pic-dark-2.webp',
    srcSetDark: '/images/content/learning/learning-pic-dark-2@2x.webp',
  },
  {
    src: '/images/content/learning/learning-pic-3.webp',
    srcSet: '/images/content/learning/learning-pic-3@2x.webp',
    srcDark: '/images/content/learning/learning-pic-dark-3.webp',
    srcSetDark: '/images/content/learning/learning-pic-dark-3@2x.webp',
  },
  */
];

const Learning = (props: {}, ref: ForwardedRef<HTMLDivElement>) => {
  return (
    <div ref={ref} className={styles.learning}>
      <div className={cn('section', styles.section)}>
        {/* container */}
        <div className={cn('container', styles.container)}>
          <div className={styles.wrap}>
            <h2 className={cn('h2', styles.title)}>Learning</h2>
            <div className={styles.subtitle}>
              Language, Qualifications, Hobbies
            </div>
            <div className={styles.info}>
              With Dbear Learning World receiving a variety of education
              discounts and specials. You can also get rewards.
            </div>
            <button
              className={cn('button', styles.button)}
              onClick={() => {
                window.open(
                  'https://master.djigl9878gj6z.amplifyapp.com/',
                  '_blank'
                );
              }}
            >
              Go to DBear Education
            </button>
          </div>
          <div className={styles.gallery}>
            {gallery.map((x, index) => (
              <div className={styles.preview} key={index}>
                <Image
                  srcSet={`${x.srcSet} 2x`}
                  srcSetDark={`${x.srcSetDark} 2x`}
                  src={x.src}
                  srcDark={x.srcDark}
                  alt="Learning"
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default forwardRef<HTMLDivElement>(Learning);
