import useDarkMode, { DarkMode } from 'use-dark-mode';
import styles from './DbcInformation.module.sass';
import cn from 'classnames';

const DbcInformation = () => {
  const darkMode: DarkMode = useDarkMode(false);
  return (
    <div className={styles.dbcInformation}>
      <div className={cn('section', styles.section)}>
        <div className={cn('container', styles.container)}>
          <div className={styles.wrap}>
            <h2 className={cn('h2', styles.title)}>Dbear DBC Information</h2>
            <div className={styles.subtitle}>
              Dbear Coin (DBC) is used as a means of exchange with Dbear
              Universe's member points and DMNC NFT Card, and in some cases, it
              can also be used as a direct reward.
            </div>
          </div>

          <div className={styles.body}>
            <div className={styles.preview}>
              <img
                src="/images/content/dbc-information/information.webp"
                alt="Dbear DBC Information"
              />
              <img
                src={
                  darkMode.value
                    ? '/images/content/dbc-information/pie-chart-black.webp'
                    : '/images/content/dbc-information/pie-chart.webp'
                }
                alt="chart"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DbcInformation;
