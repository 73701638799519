const OfficerItems = () => [
  {
    name: 'Jung Manwon',
    rank: ['Chairman & CEO'],
    career: ['Former Vice Chairman of SK Group', 'Former CEO of SK Telecom'],
    image: '/images/content/officer/officer-1.webp',
  },
  {
    name: 'Cho Jungnam',
    rank: ['Advisor'],
    career: ['Former President of KIST', 'Former Vice Chairman of SK Group'],
    image: '/images/content/officer/officer-2.webp',
  },
  {
    name: 'Yoo Changmoo',
    rank: ['Advisor'],
    career: [
      'Former Entrepreneur',
      'Former President of the Korea Trade Insurance Corporation',
    ],
    image: '/images/content/officer/officer-3.webp',
  },
  {
    name: 'Kim Dongwon',
    rank: ['Advisor'],
    career: ['Former Ambassador to Nigeria'],
    image: '/images/content/officer/officer-4.webp',
  },
  {
    name: 'Ko Jungsik',
    rank: ['Advisor'],
    career: [
      'Former Commissioner of the Korean Intellectual Property Office',
      'Former Advisor at Kim & Jang Law Firm',
    ],
    image: '/images/content/officer/officer-5.webp',
  },
  {
    name: 'Lee Jaechan',
    rank: ['Advisor'],
    career: [
      'Former Vice Chairman of Sejung Group',
      'CEO of Sejung Namo Travel',
    ],
    image: '/images/content/officer/officer-6.webp',
  },
  {
    name: 'Yoo Yongjong',
    rank: ['Advisor'],
    career: [
      'Chairman of the Korea Hotel Association',
      'CEO of Walkerhill Hotel',
    ],
    image: '/images/content/officer/officer-7.webp',
  },
  {
    name: 'Lee Hyun Kyung',
    rank: ['Executive Vice President', 'Chief Marketing Officer'],
    career: ['CMO'],
    image: '/images/content/officer/officer-8.webp',
  },
  {
    name: 'Hwang Yunha',
    rank: ['Executive Vice President', 'Chief Operating Officer'],
    career: ['COO'],
    image: '/images/content/officer/officer-9.webp',
  },
];

export default OfficerItems;
