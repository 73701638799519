import * as React from 'react';
import SvgProps from './SvgProps';

const SvgChevronDown: React.FunctionComponent<SvgProps> = ({
  width = 24,
  height = 24,
  fill = 'currentColor',
}: SvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill={fill}
    viewBox="0 0 24 24"
  >
    <defs>
      <path d="M0 0h24v24H0z" />
    </defs>
    <g clipPath="url(#chevron_down_svg__a)">
      <path d="M4.793 8.25a1 1 0 0 1 1.414 0L12 14.043l5.793-5.793a1 1 0 1 1 1.414 1.414l-5.793 5.793a2 2 0 0 1-2.828 0L4.793 9.664a1 1 0 0 1 0-1.414Z" />
    </g>
  </svg>
);
export default SvgChevronDown;
