import cn from 'classnames';
import Icon from '../Icon';
import styles from './ErrorBox.module.sass';

interface ErrorBoxProps {
  message: string;
  onClose: () => void;
}

const ErrorBox: React.FC<ErrorBoxProps> = ({ message, onClose }) => {
  return (
    <div className={cn(styles.message, { [styles.hide]: message === '' })}>
      <span>
        <Icon name="info" />
      </span>
      <div>{message}</div>
      <button className={styles.close} onClick={onClose}>
        <Icon name="close" />
      </button>
    </div>
  );
};
export default ErrorBox;
