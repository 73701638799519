import * as React from 'react';
import SvgProps from './SvgProps';

const SvgAddUser: React.FunctionComponent<SvgProps> = ({
  width = 24,
  height = 24,
  fill = 'currentColor',
}: SvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill={fill}
    viewBox="0 0 24 24"
  >
    <g clipPath="url(#Line_svg__a)">
      <path d="M10.28 0c-3.304 0-6 2.696-6 6s2.696 6 6 6c3.303 0 6-2.696 6-6s-2.697-6-6-6Zm0 1.714A4.273 4.273 0 0 1 14.564 6a4.273 4.273 0 0 1-4.286 4.286A4.273 4.273 0 0 1 5.993 6a4.273 4.273 0 0 1 4.286-4.286ZM.54 18.735c-.725 1.228-.693 2.608-.064 3.62.628 1.01 1.8 1.645 3.13 1.645h1.537a.857.857 0 1 0 0-1.714H3.606c-.8 0-1.38-.359-1.676-.836-.296-.477-.363-1.081.087-1.843 1.828-3.09 4.89-5.02 8.26-5.034 3.376.009 6.443 1.94 8.272 5.034.45.762.384 1.366.087 1.843-.296.477-.876.836-1.675.836H8.57a.857.857 0 0 0 0 1.714h8.39c1.33 0 2.502-.634 3.13-1.646.629-1.011.659-2.391-.067-3.619-2.1-3.553-5.715-5.866-9.744-5.864-4.029.002-7.64 2.314-9.74 5.864ZM20.572 7.714a.857.857 0 0 0-.858.857v1.715H18A.857.857 0 1 0 18 12h1.714v1.714a.857.857 0 0 0 1.715 0V12h1.714a.857.857 0 1 0 0-1.714h-1.714V8.57a.857.857 0 0 0-.857-.857Z" />
    </g>
    <defs>
      <clipPath id="Line_svg__a">
        <path d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgAddUser;
