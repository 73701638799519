import { Auth } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { AuthContext } from './libs/authContext';
import { UserInfoContext } from './libs/userInfoContext';
import UserInfoDao, { UserInfo } from './libs/userInfoDao';

interface CombinedProviderProps {
  children: React.ReactNode;
}

const CombinedProvider: React.FC<CombinedProviderProps> = ({ children }) => {
  // the state of the user's authentication state
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

  // the state of the userinfo state
  const [userInfo, setUserInfo] = useState<UserInfo>({
    userId: '',
    sortId: '',
    characterId: 0,
  });

  // check the user's authentication state when the app loads
  // get user info from dynamodb
  useEffect(() => {
    async function checkAuthState() {
      try {
        // check is login
        await Auth.currentSession();
        setIsAuthenticated(true);
        // get user id from session
        // const idToken = session.getIdToken().getJwtToken();
        // const payload = JSON.parse(atob(idToken.split('.')[1]));
        // const sub = payload['sub'];
        // get user id
        const user = await Auth.currentAuthenticatedUser();
        // read user info from dynamodb
        const dao = UserInfoDao();
        const userInfo = await dao.readUserInfo(user.attributes.sub);
        if (userInfo == null) {
          // if no user exists then create user info
          const createdUserInfo = await UserInfoDao().createUserInfo(
            user.attributes.sub,
            user.attributes.email
          );
          if (createdUserInfo) setUserInfo(createdUserInfo);
        } else {
          // set user info context
          setUserInfo(userInfo);
        }
      } catch (error) {
        setIsAuthenticated(false);
      }
    }
    checkAuthState();
  }, []);

  // return react context provider
  return (
    <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated }}>
      <UserInfoContext.Provider value={{ userInfo, setUserInfo }}>
        {children}
      </UserInfoContext.Provider>
    </AuthContext.Provider>
  );
};

export { AuthContext, CombinedProvider, UserInfoContext };
