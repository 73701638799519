import * as React from 'react';
import SvgProps from './SvgProps';

const SvgHexagon: React.FunctionComponent<SvgProps> = ({
  width = 24,
  height = 24,
  fill = 'currentColor',
}: SvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill={fill}
    viewBox="0 0 22 24"
  >
    <path d="M1.444 24c-.05 0-.1-.002-.153-.005-.533-.038-.836-.275-.995-.468-.539-.648-.2-1.572-.088-1.876.307-.834 1.042-1.663 2.246-2.533.777-.561 1.753-1.138 2.905-1.717a64.384 64.384 0 0 0 2.005-4.555 60.182 60.182 0 0 0 1.683-5.167 17.294 17.294 0 0 1-.866-2.027c-.314-.899-.508-1.73-.575-2.47-.07-.759-.001-1.383.202-1.854.133-.308.537-1.244 1.393-1.323.824-.075 1.369.707 1.548.965.452.651.59 1.71.421 3.238-.102.925-.318 2.033-.643 3.302a23.86 23.86 0 0 0 2.453 3.662 24.215 24.215 0 0 0 2.608 2.787 14.816 14.816 0 0 1 2.001-.208c1.227-.031 2.08.183 2.607.654.232.208.94.841.78 1.671-.05.265-.214.645-.708.956-.247.155-.493.242-.598.28-.197.069-.41.104-.638.104-.8 0-1.795-.424-2.969-1.266-.286-.206-.58-.435-.877-.684-.155.03-.313.062-.475.097a38.932 38.932 0 0 0-3.85 1.048c-.02.008-2.166.739-4.41 1.822l-.067.032c-.723 1.435-1.404 2.602-2.027 3.47-.395.55-.775.991-1.13 1.312-.459.414-.89.647-1.32.713-.092.014-.262.04-.463.04Zm-.056-1.414c.06.003.158 0 .312-.024.161-.024.93-.284 2.45-2.886-1.901 1.172-2.476 2.016-2.643 2.471a1.92 1.92 0 0 0-.12.439Zm8.615-13.2a60.838 60.838 0 0 1-1.349 3.977 63.626 63.626 0 0 1-1.322 3.118 44.016 44.016 0 0 1 3.092-1.211c.07-.024 1.622-.547 3.482-.975a26.944 26.944 0 0 1-1.998-2.227 26.658 26.658 0 0 1-1.905-2.682Zm7.14 5.803c1.089.73 1.792.886 2.073.787a1.42 1.42 0 0 0 .37-.177 1.634 1.634 0 0 0-.305-.331c-.112-.101-.5-.333-1.653-.304a9.62 9.62 0 0 0-.486.025ZM9.33 1.448a.983.983 0 0 0-.095.133 2.532 2.532 0 0 0-.158.316c-.188.436-.193 1.556.41 3.28.028.081.058.165.09.251.512-2.645.181-3.439.04-3.64a1.667 1.667 0 0 0-.287-.34Z" />
  </svg>
);
export default SvgHexagon;
