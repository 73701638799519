import * as React from 'react';
import SvgProps from './SvgProps';

const SvgFacebook: React.FunctionComponent<SvgProps> = ({
  width = 24,
  height = 24,
  fill = 'currentColor',
}: SvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill={fill}
    viewBox="0 0 24 24"
  >
    <defs>
      <clipPath id="facebook_svg__a">
        <path d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
    <g clipPath="url(#facebook_svg__a)">
      <path
        fillRule="evenodd"
        d="M12 20a8 8 0 1 0-8-8 8 8 0 0 0 8 8Zm0 2A10 10 0 1 0 2 12a10 10 0 0 0 10 10Z"
      />
      <path d="M12 10a1 1 0 0 1 1-1h1a1 1 0 0 0 0-2h-1a3 3 0 0 0-3 3v2H9a1 1 0 0 0 0 2h1v6a1 1 0 0 0 2 0v-6h2a1 1 0 0 0 0-2h-2Z" />
    </g>
  </svg>
);
export default SvgFacebook;
