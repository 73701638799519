const GalleryItems = () => [
  {
    original: '/images/content/animation-teaser/sketch-1.gif',
    thumbnail: '/images/content/animation-teaser/sketch-thumb-1.webp',
    description: '',
  },
  {
    original: '/images/content/animation-teaser/sketch-2.webp',
    thumbnail: '/images/content/animation-teaser/sketch-thumb-2.webp',
    description: '',
  },
  {
    original: '/images/content/animation-teaser/sketch-3.webp',
    thumbnail: '/images/content/animation-teaser/sketch-thumb-3.webp',
    description: '',
  },
  {
    original: '/images/content/animation-teaser/sketch-4.webp',
    thumbnail: '/images/content/animation-teaser/sketch-thumb-4.webp',
    description: '',
  },
  {
    original: '/images/content/animation-teaser/sketch-5.webp',
    thumbnail: '/images/content/animation-teaser/sketch-thumb-5.webp',
    description: '',
  },
  {
    original: '/images/content/animation-teaser/sketch-6.webp',
    thumbnail: '/images/content/animation-teaser/sketch-thumb-6.webp',
    description: '',
  },
  {
    original: '/images/content/animation-teaser/sketch-7.webp',
    thumbnail: '/images/content/animation-teaser/sketch-thumb-7.webp',
    description: '',
  },
  {
    original: '/images/content/animation-teaser/sketch-8.webp',
    thumbnail: '/images/content/animation-teaser/sketch-thumb-8.webp',
    description: '',
  },
  {
    original: '/images/content/animation-teaser/sketch-9.webp',
    thumbnail: '/images/content/animation-teaser/sketch-thumb-9.webp',
    description: '',
  },
  {
    original: '/images/content/animation-teaser/sketch-10.webp',
    thumbnail: '/images/content/animation-teaser/sketch-thumb-10.webp',
    description: '',
  },
  // 높이가 넘어가서 그냥 빼버림
  // {
  //   original: '/images/content/animation-teaser/sketch-11.webp',
  //   thumbnail: '/images/content/animation-teaser/sketch-thumb-11.webp',
  //   description: '',
  // },
  {
    original: '/images/content/animation-teaser/sketch-12.webp',
    thumbnail: '/images/content/animation-teaser/sketch-thumb-12.webp',
    description: '',
  },
  {
    original: '/images/content/animation-teaser/sketch-13.webp',
    thumbnail: '/images/content/animation-teaser/sketch-thumb-13.webp',
    description: '',
  },
  {
    original: '/images/content/animation-teaser/sketch-14.webp',
    thumbnail: '/images/content/animation-teaser/sketch-thumb-14.webp',
    description: '',
  },
];

export default GalleryItems;
