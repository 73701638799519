import * as React from 'react';
import SvgProps from './SvgProps';

const SvgArrowBackward: React.FunctionComponent<SvgProps> = ({
  width = 24,
  height = 24,
  fill = 'currentColor',
}: SvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill={fill}
    viewBox="0 0 24 24"
  >
    <defs>
      <clipPath id="arrow-backward_svg__a">
        <path d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
    <g clipPath="url(#arrow-backward_svg__a)">
      <path d="M10.75 17.793a1 1 0 1 1-1.414 1.414l-5.793-5.793a2 2 0 0 1 0-2.828l5.793-5.793a1 1 0 0 1 1.414 1.414L5.957 11h14.086a1 1 0 0 1 0 2H5.957Z" />
    </g>
  </svg>
);
export default SvgArrowBackward;
