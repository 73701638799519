import * as React from 'react';
import SvgProps from './SvgProps';

const SvgClose: React.FunctionComponent<SvgProps> = ({
  width = 24,
  height = 24,
  fill = 'currentColor',
}: SvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill={fill}
    viewBox="0 0 24 24"
  >
    <path
      fill="#B1B5C4"
      fillRule="evenodd"
      d="M16 7v3.73a1 1 0 0 0 .136.503l4.232 7.255c1.167 2-.276 4.512-2.591 4.512H6.223c-2.315 0-3.758-2.512-2.591-4.512l4.232-7.255A1 1 0 0 0 8 10.73V7a1 1 0 0 1 0-2h8a1 1 0 1 1 0 2Zm-1.591 5.241 4.232 7.255A1 1 0 0 1 17.777 21H6.223a1 1 0 0 1-.864-1.504l4.232-7.255A3 3 0 0 0 10 10.73V7h4v3.73a3 3 0 0 0 .409 1.511Z"
      clipRule="evenodd"
    />
    <path
      fill="#B1B5C4"
      d="M5 17a1 1 0 0 1 1-1h12a1 1 0 1 1 0 2H6a1 1 0 0 1-1-1Z"
    />
    <path
      fill="#777E91"
      d="M14 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM10 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
    />
  </svg>
);
export default SvgClose;
