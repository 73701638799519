import cn from 'classnames';
import { useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { Link } from 'react-router-dom';
import Icon from '../../Icon';
import styles from './Language.module.sass';

interface MenuItem {
  title: string;
  url: string;
}

interface LanguageProps {
  className?: string;
  items: MenuItem[];
}

const Language: React.FC<LanguageProps> = ({ className, items }) => {
  const [visible, setVisible] = useState(false);

  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      <div
        className={cn(className, styles.language, { [styles.active]: visible })}
      >
        {/* nav menu head */}
        <button className={styles.head} onClick={() => setVisible(!visible)}>
          <Icon name="globe" width={18} height={18} />
          <div className={styles.text}>Language</div>
        </button>

        {/* nav menu body */}
        <div className={styles.body}>
          <div className={styles.list}>
            {items.map((x, index) => (
              <Link
                className={cn(styles.item, { [styles.active]: index === 0 })}
                key={index}
                to={x.url}
              >
                <div className={styles.title}>{x.title}</div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default Language;
