import AWS from 'aws-sdk';

// the table name - user info
const TableName = 'universe-user-info';

// the sort key
const SortIdUserInfo = 'userInfo';

// the user info interface
interface UserInfo {
  userId: string;
  sortId: string;
  characterId?: number;
  email?: string;
  joinDate?: number;
}

const UserInfoDao = () => {
  // initialize dynamodb client
  const dynamodb = new AWS.DynamoDB.DocumentClient();

  // create user info and save to dynamodb
  const createUserInfo = async (
    userId: string,
    email: string
  ): Promise<UserInfo | null> => {
    const userInfo = {
      userId: userId,
      sortId: SortIdUserInfo,
      email: email,
      characterId: Math.floor(Math.random() * 7) + 1,
      joinDate: Date.now(),
    };
    const params = {
      TableName: TableName,
      Item: userInfo,
    };
    try {
      await dynamodb.put(params).promise();
      return userInfo;
    } catch (error: unknown) {
      if (error instanceof Error) {
        console.error('Error saving DynamoDB:', error.message);
      }
      return null;
    }
  };

  // get user info from dynamodb
  const readUserInfo = async (userId: string): Promise<UserInfo | null> => {
    let userInfo: UserInfo | null = null;
    const params = {
      TableName: TableName,
      Key: {
        userId: userId,
        sortId: SortIdUserInfo,
      },
    };
    try {
      const result = await dynamodb.get(params).promise();
      if (result.Item) {
        userInfo = {
          userId: result.Item.userId,
          sortId: result.Item.sortId,
          email: result.Item.email,
          characterId: result.Item.characterId,
          joinDate: result.Item.joinDate,
        };
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        console.error('Error reading DynamoDB:', error.message);
      }
    } finally {
      return userInfo;
    }
  };

  return { createUserInfo, readUserInfo };
};

export type { UserInfo };
export default UserInfoDao;
