import cn from 'classnames';
import React from 'react';
import { use100vh } from 'react-div-100vh';
import { Link } from 'react-router-dom';
import Image from '../../../components/Image';
import Entry from './Entry';
import styles from './Join.module.sass';
import Code from './Code';

const Join = () => {
  // the Features list
  const items = ['Learning', 'Playing', 'Shopping', 'NFTS'];

  // the height of window
  const heightWindow = use100vh();

  // the state for email only use for code validator props
  const [email, setEmail] = React.useState('');

  // the state for password only use for code validator props
  const [password, setPassword] = React.useState('');

  // the state for entries or code validator visible
  const [isEntriesVisible, setIsEntriesVisible] = React.useState(true);

  // the handle done register user callback
  // set value and change visible state
  const onRegisterDone = (email: string, password: string) => {
    setEmail(email);
    setPassword(password);
    setIsEntriesVisible(false);
  };

  return (
    <div
      className={styles.auth}
      style={{ minHeight: heightWindow ? `${heightWindow}px` : undefined }}
    >
      {/* column - first: sidebar */}
      <div className={styles.col}>
        <div className={styles.sidebar}>
          <div className={styles.preview}>
            <img src="/images/content/auth/login-pic.webp" alt="Login" />
          </div>
          <div className={cn('h4', styles.subtitle)}>D:Bear Universe</div>
          <ul className={styles.list}>
            {items.map((x, index) => (
              <li key={index}>{x}</li>
            ))}
          </ul>
        </div>
      </div>

      {/* column - second: join form */}
      <div className={styles.col}>
        {/* header */}
        <div className={styles.header}>
          {/* logo */}
          <Link className={styles.logo} to="/">
            <Image
              src="/images/logo/symbol-dark.svg"
              srcDark="/images/logo/symbol-light.svg"
              alt="D:Bear"
            />
          </Link>
          {/* link - go to sign up */}
          <div className={styles.topLink}>
            Already a member? <Link to="/login">Login</Link>
          </div>
        </div>

        {/* wrapper - form entries or code validator */}
        <div className={styles.wrapper}>
          {/* title */}
          <div className={cn('h2', styles.title)}>Join</div>
          {/* entry or code */}
          {isEntriesVisible ? (
            <Entry onRegisterDone={onRegisterDone} />
          ) : (
            <Code email={email} password={password} />
          )}
        </div>
      </div>
    </div>
  );
};
export default Join;
