import * as React from 'react';
import SvgProps from './SvgProps';

const SvgClose: React.FunctionComponent<SvgProps> = ({
  width = 24,
  height = 24,
  fill = 'currentColor',
}: SvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill={fill}
    viewBox="0 0 24 24"
  >
    <path
      fill="#B1B5C4"
      fillRule="evenodd"
      d="m15.643 16 .599.828a2.826 2.826 0 0 0 5.096-2.004l-.775-6.196A3 3 0 0 0 17.586 6H6.414a3 3 0 0 0-2.977 2.628l-.774 6.196a2.826 2.826 0 0 0 5.095 2.004l.6-.828h7.285Zm-1.022 2a4.826 4.826 0 0 0 8.7-3.424l-.773-6.196A5 5 0 0 0 17.586 4H6.414a5 5 0 0 0-4.961 4.38l-.775 6.196A4.826 4.826 0 0 0 9.378 18h5.243Z"
      clipRule="evenodd"
    />
    <path
      fill="#B1B5C4"
      d="M8 8a1 1 0 0 0-1 1v1H6a1 1 0 1 0 0 2h1v1a1 1 0 1 0 2 0v-1h1a1 1 0 1 0 0-2H9V9a1 1 0 0 0-1-1Z"
    />
    <path
      fill="#777E91"
      d="M18 10a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM16 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
    />
  </svg>
);
export default SvgClose;
