import cn from 'classnames';
import { Link } from 'react-router-dom';
import { useAuthContext } from '../../libs/authContext';
import Image from '../Image';
import styles from './Header.module.sass';
import Language from './Language';
import User from './User';

const languages = [
  {
    title: 'English',
    url: '',
  },
  // {
  //   title: '한국어',
  //   url: '',
  // },
];

const userMenus = [
  {
    menu: [
      // {
      //   type: 'link',
      //   title: 'Account settings',
      //   icon: 'settings',
      //   url: '/',
      // },
      {
        type: 'button',
        title: 'Logout',
        icon: 'logout',
        url: '/',
      },
    ],
  },
];

const Header = () => {
  // the state for login state
  const { isAuthenticated } = useAuthContext();

  return (
    <div className={styles.header}>
      <div className={cn('container', styles.container)}>
        {/* logo */}
        <Link className={styles.logo} to="/">
          <Image
            src="/images/logo/logo-dark.svg"
            srcDark="/images/logo/logo-light.svg"
            alt="D:Bear"
          />
        </Link>

        {/* center items */}
        <div className={cn(styles.wrapper)}>
          {/* language */}
          <Language className={styles.language} items={languages} />
        </div>

        {/* sign in or user */}
        {!isAuthenticated ? (
          <div className={styles.btns}>
            <Link className={styles.link} to="/login">
              Login
            </Link>
            <Link className={cn('button')} to="/join">
              Join
            </Link>
          </div>
        ) : (
          <User className={styles.user} items={userMenus} />
        )}
      </div>
    </div>
  );
};

export default Header;
