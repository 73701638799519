import * as React from 'react';
import SvgProps from './SvgProps';

const SvgUserLine: React.FunctionComponent<SvgProps> = ({
  width = 24,
  height = 24,
  fill = 'currentColor',
}: SvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill={fill}
    viewBox="0 0 24 24"
  >
    <path
      fill="#B1B5C4"
      fillRule="evenodd"
      d="M12 13a7 7 0 0 0-7 7v2a1 1 0 1 1-2 0v-2a9 9 0 1 1 18 0v2a1 1 0 1 1-2 0v-2a7 7 0 0 0-7-7Z"
      clipRule="evenodd"
    />
    <path
      fill="#B1B5C4"
      fillRule="evenodd"
      d="M12 11a4 4 0 1 0 0-8 4 4 0 0 0 0 8Zm0 2a6 6 0 1 0 0-12 6 6 0 0 0 0 12Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgUserLine;
