import { Auth } from 'aws-amplify';
import cn from 'classnames';
import React, { useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import ErrorBox from '../../../../components/ErrorBox';
import Loader from '../../../../components/Loader';
import TextInput from '../../../../components/TextInput';
import { checkConfirm, checkPassword } from '../../Join/Entry/validate';
import styles from './Code.module.sass';

interface Props {
  email: string;
}

const Code: React.FC<Props> = ({ email }) => {
  // the navigate hook
  const navigate = useNavigate();

  // the ref for input - password
  const inputPasswordRef = useRef<HTMLInputElement | null>(null);

  // the ref for input - confirm password
  const inputConfirmRef = useRef<HTMLInputElement | null>(null);

  // the state for validation code
  const [code, setCode] = React.useState('');

  // the state for password
  const [password, setPassword] = React.useState('');

  // the state for password validation result text
  const [passwordValid, setPasswordValid] = React.useState('');

  // the state for confirm password
  const [confirm, setConfirm] = React.useState('');

  // the state for confirm password validation result text
  const [confirmValid, setConfirmValid] = React.useState('');

  // the state for register error message
  const [errorMsg, setErrorMsg] = React.useState('');

  // the state for loading while in login process
  const [isLoading, setIsLoading] = React.useState(false);

  // on input value change event - password
  // password & confirm valid check
  const onChangePassword = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const passwordCurrent = e.target.value;
      setPassword(passwordCurrent);
      setPasswordValid(checkPassword(passwordCurrent));
      setConfirmValid(checkConfirm(passwordCurrent, confirm));
    },
    [confirm]
  );

  // on input value change event - confirm
  const onChangeConfirm = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const confirmCurrent = e.target.value;
      setConfirm(confirmCurrent);
      setConfirmValid(checkConfirm(password, confirmCurrent));
    },
    [password]
  );

  // the handle key press
  const handleKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>,
    callback: () => void
  ) => {
    // callback on enter key press
    if (event.key === 'Enter' || event.key === 'NumpadEnter') {
      callback();
    }
  };

  // the handle go next input field focus
  const handleGoNextInput = (
    data: string,
    validCheckMsg: string,
    ref: React.MutableRefObject<HTMLInputElement | null>
  ) => {
    // data empty or validation error then do nothing
    if (!data || validCheckMsg) return;
    if (ref && ref.current) ref.current.focus();
  };

  const handleResetPassword = async () => {
    try {
      // 버튼이 disabled 상태여도 tab으로 접근해서 엔터로 클릭할 수 있기 때문에
      // validation 체크를 한번 더 해준다.
      if (!code || !password) return;
      if (isLoading) return;
      setIsLoading(true);
      await Auth.forgotPasswordSubmit(email, code, password);
      navigate('/login');
    } catch (error: unknown) {
      if (error instanceof Error) setErrorMsg(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.form}>
      {/* form title */}
      <div className={styles.formTitle}>Set a new password</div>

      {/* input for code */}
      <TextInput
        className={styles.field}
        name="tel"
        type="tel"
        placeholder="Code"
        required
        icon="check"
        onChange={(e) => setCode(e.target.value)}
        onKeyDown={(e) =>
          handleKeyPress(e, () => handleGoNextInput(code, '', inputPasswordRef))
        }
      />

      {/* input for password */}
      <TextInput
        className={styles.field}
        placeholder="Password"
        name="password"
        type="password"
        icon="lock"
        required
        error={passwordValid !== ''}
        inputRef={inputPasswordRef}
        onChange={onChangePassword}
        onKeyDown={(e) =>
          handleKeyPress(e, () =>
            handleGoNextInput(password, passwordValid, inputConfirmRef)
          )
        }
      />
      {/* validation check message for password */}
      <p className={cn(styles.invalid, { [styles.hide]: !passwordValid })}>
        {passwordValid}
      </p>

      {/* input for confirm password */}
      <TextInput
        className={styles.field}
        placeholder="Confirm Password"
        name="password"
        type="password"
        icon="lock"
        required
        error={confirmValid !== ''}
        inputRef={inputConfirmRef}
        onChange={onChangeConfirm}
        onKeyDown={(e) => handleKeyPress(e, () => handleResetPassword())}
      />
      {/* validation check message for confirm password */}
      <p className={cn(styles.invalid, { [styles.hide]: !confirmValid })}>
        {confirmValid}
      </p>

      {/* error box for login error message */}
      <ErrorBox message={errorMsg} onClose={() => setErrorMsg('')} />

      {/* reset password button */}
      <button
        className={cn('button', styles.button, {
          disabled:
            !code || !password || !confirm || passwordValid || confirmValid,
        })}
        onClick={handleResetPassword}
      >
        <Loader
          className={cn(styles.loader, { [styles.hide]: !isLoading })}
          white
        />
        <span>Continue</span>
      </button>
    </div>
  );
};

export default Code;
