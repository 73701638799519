import * as React from 'react';
import SvgProps from './SvgProps';

const SvgTwitter: React.FunctionComponent<SvgProps> = ({
  width = 24,
  height = 24,
  fill = 'currentColor',
}: SvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill={fill}
    viewBox="0 0 24 24"
  >
    <path
      fill="#777E91"
      stroke="#777E91"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M19.083 21c-.927 0-1.998-.651-2.554-1.04a27.457 27.457 0 0 0-.601-.391c-1.25-.806-3.852-2.482-4.983-3.425-.343-.29-.533-.643-.534-.994-.002-.371.195-.73.587-1.069a209.888 209.888 0 0 0 5.779-5.427c.328-.323.519-.715.52-.846-.1-.006-.5.056-1.68.846L7.842 13.82c-.026.015-.488.295-1.344.295-.434 0-.897-.072-1.375-.214-1.64-.485-3.573-1.138-3.592-1.144a.212.212 0 0 1-.045-.02c-.021-.014-.53-.334-.483-.84.044-.464.52-.865 1.457-1.225 2.19-.882 8.329-3.383 18.24-7.43l.172-.07c.023-.01.452-.172.916-.172.395 0 .703.115.913.342.236.254.333.635.288 1.132-.04.556-.279 2.085-.582 4.02-.113.726-.235 1.505-.357 2.3l-1.379 8.537C20.477 20.709 19.7 21 19.083 21ZM17.286 7.382c.277 0 .377.168.408.24.158.376-.292 1.017-.612 1.333a209.787 209.787 0 0 1-5.797 5.443c-.297.257-.445.508-.444.75.002.304.242.551.384.672 1.11.926 3.793 2.654 4.938 3.393.393.252.568.366.62.404.409.285 1.462.957 2.3.958.657 0 1.038-.427 1.161-1.307l1.38-8.54c.121-.793.243-1.572.357-2.299.289-1.849.539-3.446.578-3.989.034-.377-.026-.648-.176-.81-.126-.136-.326-.205-.595-.205-.381 0-.752.14-.756.141l-.166.068a6270.75 6270.75 0 0 1-18.247 7.433c-.997.384-1.171.71-1.187.87-.02.206.203.382.266.427.243.081 2.024.679 3.549 1.13.438.13.858.195 1.251.195.721 0 1.106-.225 1.11-.227l7.767-5.16c.924-.62 1.549-.92 1.911-.92Z"
    />
  </svg>
);
export default SvgTwitter;
