import { Auth } from 'aws-amplify';
import cn from 'classnames';
import React, { useRef } from 'react';
import { use100vh } from 'react-div-100vh';
import { Link, useNavigate } from 'react-router-dom';
import ErrorBox from '../../../components/ErrorBox';
import Image from '../../../components/Image';
import Loader from '../../../components/Loader';
import TextInput from '../../../components/TextInput';
import { useAuthContext } from '../../../libs/authContext';
import { useUserInfoContext } from '../../../libs/userInfoContext';
import UserInfoDao from '../../../libs/userInfoDao';
import styles from './Login.module.sass';

const Login = () => {
  // the height of window
  const heightWindow = use100vh();

  // the navigate hook
  const navigate = useNavigate();

  // the ref for input
  const inputPasswordRef = useRef<HTMLInputElement | null>(null);

  // the state for email
  const [email, setEmail] = React.useState('');

  // the state for password
  const [password, setPassword] = React.useState('');

  // the state for login error message
  const [errorMsg, setErrorMsg] = React.useState('');

  // the state for loading while in login process
  const [isLoading, setIsLoading] = React.useState(false);

  // the state(set) for login state
  const { setIsAuthenticated } = useAuthContext();

  // the state of the userinfo state
  const { setUserInfo } = useUserInfoContext();

  // the handle key press
  const handleKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>,
    callback: () => void
  ) => {
    if (event.key === 'Enter' || event.key === 'NumpadEnter') {
      callback();
    }
  };

  // the handle go next input field focus
  const handleGoNextInput = () => {
    if (!password) return;
    if (inputPasswordRef && inputPasswordRef.current) {
      inputPasswordRef.current.focus();
    }
  };

  // the handle get user info
  const handleGetUserInfo = async () => {
    try {
      // get user id
      const user = await Auth.currentAuthenticatedUser();
      // read user info from dynamodb
      const dao = UserInfoDao();
      const userInfo = await dao.readUserInfo(user.attributes.sub);
      if (userInfo == null) {
        // if no user exists then create user info
        const createdUserInfo = await UserInfoDao().createUserInfo(
          user.attributes.sub,
          user.attributes.email
        );
        if (createdUserInfo) setUserInfo(createdUserInfo);
      } else {
        // set user info context
        setUserInfo(userInfo);
      }
    } catch (error: unknown) {
      setIsAuthenticated(false);
      if (error instanceof Error) console.error(error.toString());
    }
  };

  // the handle login
  const handleLogin = async () => {
    try {
      if (!password || !email) return; // prevent from keypress enter
      if (isLoading) return;
      setIsLoading(true);
      await Auth.signIn(email, password);
      setIsAuthenticated(true);
      await handleGetUserInfo();
      navigate('/');
    } catch (error: unknown) {
      if (error instanceof Error) setErrorMsg(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  // the login component
  return (
    <div
      className={styles.auth}
      style={{ minHeight: heightWindow ? `${heightWindow}px` : undefined }}
    >
      {/* link - go to sign up */}
      <div className={styles.topLink}>
        Don’t have an account?&nbsp;<Link to="/join">Join</Link>
      </div>

      <div className={styles.wrapper}>
        {/* header */}
        <div className={styles.header}>
          {/* logo */}
          <Link className={styles.logo} to="/">
            <Image
              src="/images/logo/symbol-dark.svg"
              srcDark="/images/logo/symbol-light.svg"
              alt="D:Bear"
            />
          </Link>
          {/* title */}
          <div className={cn('h2', styles.title)}>Login</div>
        </div>

        {/* login form - email login */}
        <div className={styles.form}>
          {/* form title */}
          <div className={styles.formTitle}>Login with email address</div>
          {/* input for email  */}
          <TextInput
            className={styles.field}
            placeholder="Email"
            name="email"
            type="email"
            icon="mail"
            required
            onChange={(e) => setEmail(e.target.value)}
            onKeyDown={(e) => handleKeyPress(e, handleGoNextInput)}
          />
          {/* input for password */}
          <TextInput
            className={styles.field}
            placeholder="Password"
            name="password"
            type="password"
            icon="lock"
            required
            inputRef={inputPasswordRef}
            onChange={(e) => setPassword(e.target.value)}
            onKeyDown={(e) => handleKeyPress(e, handleLogin)}
          />
          {/* error box for login error message */}
          <ErrorBox message={errorMsg} onClose={() => setErrorMsg('')} />
          {/* login button */}
          <button
            className={cn('button', styles.button, {
              disabled: !email || !password,
            })}
            onClick={handleLogin}
          >
            <Loader
              className={cn(styles.loader, { [styles.hide]: !isLoading })}
              white
            />
            <span>Login</span>
          </button>
          {/* link - go to reset password */}
          <div className={styles.textLink}>
            <Link to="/reset">Forgot password?</Link>
          </div>
        </div>

        {/* SSO Login */}
        {/* <Divider text="OR" /> */}
      </div>
    </div>
  );
};

export default Login;
