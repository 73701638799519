import * as React from 'react';
import SvgProps from './SvgProps';

const SvgArrowForward: React.FunctionComponent<SvgProps> = ({
  width = 24,
  height = 24,
  fill = 'currentColor',
}: SvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill={fill}
    viewBox="0 0 24 24"
  >
    <defs>
      <clipPath id="arrow-forward_svg__a">
        <path d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
    <g clipPath="url(#arrow-forward_svg__a)">
      <path d="M13.244 18.179a1.122 1.122 0 0 0 0 1.508.949.949 0 0 0 1.407 0l5.765-6.179a2.243 2.243 0 0 0 0-3.017l-5.764-6.179a.949.949 0 0 0-1.407 0 1.122 1.122 0 0 0 0 1.508l4.77 5.112H4a1.069 1.069 0 0 0 0 2.135h14.014Z" />
    </g>
  </svg>
);
export default SvgArrowForward;
