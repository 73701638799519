import cn from 'classnames';
import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import ReactPlayer from 'react-player';
import styles from './AnimationPlayer.module.sass';

const AnimationPlayer: React.FC = () => {
  // the useInView hook is used to detect if the player is in view
  const { ref, inView } = useInView({
    threshold: 0.5, // 50% of the player must be visible
  });

  // the playing state is used to control the video play/pause
  const [isPlaying, setIsPlaying] = useState<boolean>(false);

  // the video url is from the aws s3 bucket
  const videoUrl =
    'https://dbear-universe-public-storage.s3.ap-northeast-2.amazonaws.com/dalgomi_teaser1.mp4';

  // the playing state is set to true when the player is in view
  useEffect(() => {
    if (inView) {
      setIsPlaying(true);
    } else {
      setIsPlaying(false);
    }
  }, [inView]);

  return (
    <div className={styles.animationPlayer}>
      <div className={styles.preview}>
        <picture>
          {/* <source
                media="(max-width: 767px)"
                srcSet="/images/content/main/hero-pic-mobile.webp"
              /> */}
          <img
            className={styles.background}
            src="/images/content/animation-teaser/teaser-background.webp"
            alt="teaser"
          />
        </picture>
        <div className={styles.wrap}>
          <h2 className={cn('h2', styles.title)}>Animation Series</h2>
          <div className={styles.subtitle}>Concept Teaser</div>
        </div>
        <div ref={ref} className={styles.videoWrapper}>
          <ReactPlayer
            className={styles.videoPlayer}
            url={videoUrl}
            playing={isPlaying}
            muted={true}
            controls={true}
            pip={true}
            config={{
              file: {
                attributes: {
                  controlsList: 'nodownload',
                },
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default AnimationPlayer;
