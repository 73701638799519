import cn from 'classnames';
import styles from './TextInput.module.sass';
import Icon from '../Icon';

interface TextInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  icon?: string;
  error?: boolean;
  inputRef?: React.RefObject<HTMLInputElement>;
}

const TextInput: React.FC<TextInputProps> = ({
  className,
  icon,
  error,
  inputRef,
  ...props
}) => {
  return (
    <div
      className={cn(
        styles.field,
        { [styles.fieldIcon]: icon },
        { [styles.error]: error },
        className
      )}
    >
      <div className={styles.wrap}>
        <input className={cn(styles.input)} ref={inputRef} {...props} />
        {icon && (
          <div className={styles.icon}>
            <Icon name={icon} />
          </div>
        )}
        {icon && (
          <div className={styles.icon}>
            <Icon name={icon} />
          </div>
        )}
      </div>
    </div>
  );
};

export default TextInput;
