import * as React from 'react';
import SvgProps from './SvgProps';

const SvgLogout: React.FunctionComponent<SvgProps> = ({
  width = 24,
  height = 24,
  fill = 'currentColor',
}: SvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill={fill}
    viewBox="0 0 24 24"
  >
    <defs>
      <clipPath id="logout_svg__a">
        <path d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
    <g clipPath="url(#logout_svg__a)">
      <path
        stroke="rgba(0,0,0,0)"
        d="M5.958 21.999a3 3 0 0 1-3-3v-14a3 3 0 0 1 3-3h6a3 3 0 0 1 3 3v1a1 1 0 0 1-1 1 1 1 0 0 1-1-1v-1a1 1 0 0 0-1-1h-6a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-1a1 1 0 0 1 1-1 1 1 0 0 1 1 1v1a3 3 0 0 1-3 3Zm10.293-5.792a1 1 0 0 1 0-1.415l1.794-1.794H8.958a1 1 0 0 1-1-1 1 1 0 0 1 1-1h9.086L16.25 9.206a1 1 0 0 1 0-1.415 1 1 0 0 1 1.415 0l2.792 2.793a2 2 0 0 1 0 2.828l-2.792 2.795a1 1 0 0 1-.708.293 1 1 0 0 1-.707-.293Z"
      />
    </g>
  </svg>
);
export default SvgLogout;
