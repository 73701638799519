import { Auth } from 'aws-amplify';
import cn from 'classnames';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import ErrorBox from '../../../../components/ErrorBox';
import Loader from '../../../../components/Loader';
import TextInput from '../../../../components/TextInput';
import { useAuthContext } from '../../../../libs/authContext';
import { useUserInfoContext } from '../../../../libs/userInfoContext';
import UserInfoDao from '../../../../libs/userInfoDao';
import styles from './Code.module.sass';

interface Props {
  email: string;
  password: string;
}

const Code: React.FC<Props> = ({ email, password }) => {
  // the navigate hook
  const navigate = useNavigate();

  // the authenticate hool
  const { setIsAuthenticated } = useAuthContext();

  // the state of the userinfo state
  const { setUserInfo } = useUserInfoContext();

  // the state for validation code
  const [code, setCode] = React.useState('');

  // the state for confirm error message
  const [errorMsg, setErrorMsg] = React.useState('');

  // the state for loading while in confirm process
  const [isLoadingConfirm, setIsLoadingConfirm] = React.useState(false);

  // the state for loading while in resend process
  const [isLoadingResend, setIsLoadingResend] = React.useState(false);

  // the handle key press
  const handleKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>,
    callback: () => void
  ) => {
    // callback on enter key press
    if (event.key === 'Enter' || event.key === 'NumpadEnter') {
      callback();
    }
  };

  // the handle save user info
  const handleSaveUserInfo = async () => {
    try {
      // get user id
      const user = await Auth.currentAuthenticatedUser();
      // save user info to dynamodb
      const createdUserInfo = await UserInfoDao().createUserInfo(
        user.attributes.sub,
        user.attributes.email
      );
      // set user info context
      if (createdUserInfo) setUserInfo(createdUserInfo);
    } catch (error: unknown) {
      if (error instanceof Error) console.error(error.toString());
    }
  };

  // the handle confirm button click
  const handleConfirm = async () => {
    try {
      // 버튼이 disabled 상태여도 tab으로 접근해서 엔터로 클릭할 수 있기 때문에
      // validation 체크를 한번 더 해준다.
      if (!code) return;

      if (isLoadingConfirm) return;
      setIsLoadingConfirm(true);
      await Auth.confirmSignUp(email, code);
      await Auth.signIn(email, password);
      setIsAuthenticated(true);
      handleSaveUserInfo();
      navigate('/');
    } catch (error: unknown) {
      if (error instanceof Error) setErrorMsg(error.message);
    } finally {
      setIsLoadingConfirm(false);
    }
  };

  // the handle resend code button click
  const handleResend = async () => {
    try {
      if (isLoadingResend) return;
      setIsLoadingResend(true);
      await Auth.resendSignUp(email);
    } catch (error: unknown) {
      if (error instanceof Error) setErrorMsg(error.message);
    } finally {
      setIsLoadingResend(false);
    }
  };

  return (
    <div className={styles.form}>
      {/* form title */}
      <div className={styles.formTitle}>
        We just send you a verify code. Check your email to get them.
      </div>

      {/* input for code */}
      <TextInput
        className={styles.field}
        placeholder="Code"
        name="text"
        type="text"
        icon="check"
        required
        onChange={(e) => setCode(e.target.value)}
        onKeyDown={(e) => handleKeyPress(e, handleConfirm)}
      />

      {/* error box for login error message */}
      <ErrorBox message={errorMsg} onClose={() => setErrorMsg('')} />

      {/* confirm button */}
      <button
        className={cn('button', styles.button, {
          disabled: isLoadingResend || !code,
        })}
        onClick={handleConfirm}
      >
        <Loader
          className={cn(styles.loader, { [styles.hide]: !isLoadingConfirm })}
          white
        />
        <span>Confirm</span>
      </button>

      {/* resend button */}
      <button
        className={cn('button-white', styles.button, {
          disabled: isLoadingConfirm,
        })}
        onClick={handleResend}
      >
        <Loader
          className={cn(styles.loader, { [styles.hide]: !isLoadingResend })}
        />
        <span>Resend Code</span>
      </button>
    </div>
  );
};

export default Code;
