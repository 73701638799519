import * as React from 'react';
import SvgProps from './SvgProps';

const SvgInstagram: React.FunctionComponent<SvgProps> = ({
  width = 24,
  height = 24,
  fill = 'currentColor',
}: SvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill={fill}
    viewBox="0 0 24 24"
  >
    <defs>
      <clipPath id="instagram_svg__a">
        <path d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
    <g clipPath="url(#instagram_svg__a)">
      <path
        fillRule="evenodd"
        d="M16 4H8a4 4 0 0 0-4 4v8a4 4 0 0 0 4 4h8a4 4 0 0 0 4-4V8a4 4 0 0 0-4-4ZM8 2a6 6 0 0 0-6 6v8a6 6 0 0 0 6 6h8a6 6 0 0 0 6-6V8a6 6 0 0 0-6-6Z"
      />
      <path d="M17 8a1 1 0 1 0-1-1 1 1 0 0 0 1 1Z" />
      <path
        fillRule="evenodd"
        d="M17 12a5 5 0 1 1-5-5 5 5 0 0 1 5 5Zm-2 0a3 3 0 1 1-3-3 3 3 0 0 1 3 3Z"
      />
    </g>
  </svg>
);
export default SvgInstagram;
