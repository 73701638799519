import Icon from '../../../../components/Icon';
import { useUserInfoContext } from '../../../../libs/userInfoContext';
import styles from './PanelItem.module.sass';
import cn from 'classnames';

interface PanelItemProp {
  title: string;
  description: string;
  icon: string;
  onClick?: () => void;
}

const PanelItem: React.FC<PanelItemProp> = ({
  title,
  description,
  icon,
  onClick,
}) => {
  // the state for user info
  const { userInfo } = useUserInfoContext();

  // the handle get image path by character id
  const getImagePath = (): string => {
    switch (userInfo.characterId) {
      case 1:
        return '/images/content/main-login/avatar-1-hani.svg';
      case 2:
        return '/images/content/main-login/avatar-2-toni.svg';
      case 3:
        return '/images/content/main-login/avatar-3-seeni.svg';
      case 4:
        return '/images/content/main-login/avatar-4-gomi.svg';
      case 5:
        return '/images/content/main-login/avatar-5-roni.svg';
      case 6:
        return '/images/content/main-login/avatar-6-dani.svg';
      case 7:
        return '/images/content/main-login/avatar-7-pani.svg';
      default:
        return '/images/content/main-login/avatar-default.svg';
    }
  };

  return (
    <div
      className={cn(styles.head, { [styles.avatar]: icon === 'avatar' })}
      onClick={onClick}
    >
      {icon === 'avatar' ? (
        <div className={styles.avatar}>
          <img src={getImagePath()} alt="Avatar" />
        </div>
      ) : (
        <div className={styles.icon}>
          <Icon name={icon} width={24} height={24} />
        </div>
      )}
      <div className={styles.title}>{title}</div>
      <div className={styles.description}>{description}</div>
    </div>
  );
};

export default PanelItem;
