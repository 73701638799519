import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';
import cn from 'classnames';
import React, { useCallback, useEffect } from 'react';
import { createPortal } from 'react-dom';
import OutsideClickHandler from 'react-outside-click-handler';
import Icon from '../Icon';
import styles from './Modal.module.sass';

interface Props {
  outerClassName?: string;
  visible: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

const Modal: React.FC<Props> = ({
  outerClassName,
  visible,
  onClose,
  children,
}) => {
  // the handle close modal
  const escFunction = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onClose();
      }
    },
    [onClose]
  );

  // the event listener that calls the handler function whenever the key is pressed
  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);
    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, [escFunction]);

  // the  prevent background scroll when modal is open
  useEffect(() => {
    const target = document.querySelector('#modal');
    if (!target) return;
    if (visible) disableBodyScroll(target as HTMLElement);
    else clearAllBodyScrollLocks();
    return () => clearAllBodyScrollLocks();
  }, [visible]);

  return createPortal(
    visible && (
      <div className={styles.modal} id="modal">
        <div className={cn(styles.outer, outerClassName)}>
          <OutsideClickHandler onOutsideClick={onClose}>
            {children}
            <button className={styles.close} onClick={onClose}>
              <Icon name="close" width={24} height={24} />
            </button>
          </OutsideClickHandler>
        </div>
      </div>
    ),
    document.body
  );
};
export default Modal;
