import { createContext, useContext } from 'react';

// 인증 상태 타입 정의
type AuthContextProps = {
  // 사용자가 인증되었는지 여부
  isAuthenticated: boolean;
  // 인증 상태 변경 함수
  setIsAuthenticated: (state: boolean) => void;
};

// AuthContextProps 혹은 null을 받는 컨텍스트 생성
export const AuthContext = createContext<AuthContextProps | null>(null);

// AuthContext 를 컴포넌트에서 사용할 수 있도록 커스텀 훅 생성
export function useAuthContext() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error(
      'useAuthContext must be used within an AppContext Provider'
    );
  }

  return context;
}

export type { AuthContextProps };
