import cn from 'classnames';
import React from 'react';
import styles from './Item.module.sass';

export interface ItemProps {
  item: {
    title: string;
    number: string;
    image: string;
  };
  className?: string;
}

const Item: React.FC<ItemProps> = ({ item, className }) => {
  return (
    <div className={cn(styles.item, className)}>
      <div className={styles.preview}>
        <img src={item.image} alt="Shopping" />
      </div>
      <div className={styles.title}>{item.title}</div>
    </div>
  );
};

export default Item;
