import Icon from '../../../../components/Icon';
import styles from './PanelItem.module.sass';

interface PanelItemProp {
  title: string;
  description: string;
  icon: string;
  onClick?: () => void;
}

const PanelItem: React.FC<PanelItemProp> = ({
  title,
  description,
  icon,
  onClick,
}) => {
  return (
    <div className={styles.head} onClick={onClick}>
      <div className={styles.icon}>
        <Icon name={icon} width={24} height={24} />
      </div>
      <div className={styles.title}>{title}</div>
      <div className={styles.description}>{description}</div>
    </div>
  );
};

export default PanelItem;
