import * as React from 'react';
import SvgProps from './SvgProps';

const SvgLock: React.FunctionComponent<SvgProps> = ({
  width = 24,
  height = 24,
  fill = 'currentColor',
}: SvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill={fill}
    viewBox="0 0 24 24"
  >
    <defs>
      <clipPath id="lock_svg__a">
        <path d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
    <g clipPath="url(#lock_svg__a)">
      <path
        stroke="rgba(0,0,0,0)"
        d="M7 21a2.865 2.865 0 0 1-3-2.7v-7.2a2.866 2.866 0 0 1 3-2.7v-.9A4.775 4.775 0 0 1 12 3a4.775 4.775 0 0 1 5 4.5v.9a2.866 2.866 0 0 1 3 2.7v7.2a2.865 2.865 0 0 1-3 2.7Zm-1-9.9v7.2a.955.955 0 0 0 1 .9h10a.955.955 0 0 0 1-.9v-7.2a.955.955 0 0 0-1-.9H7a.955.955 0 0 0-1 .9Zm3-3.6v.9h6v-.9a2.865 2.865 0 0 0-3-2.7 2.865 2.865 0 0 0-3 2.7Zm2.073 9v-1.141a1.775 1.775 0 0 1-1.011-1.559 1.92 1.92 0 0 1 2.021-1.8 1.92 1.92 0 0 1 2.022 1.8 1.774 1.774 0 0 1-1.012 1.559V16.5a.959.959 0 0 1-1.011.9.96.96 0 0 1-1.008-.9Z"
      />
    </g>
  </svg>
);
export default SvgLock;
