import cn from 'classnames';
import React from 'react';
import styles from './Item.module.sass';

export interface ItemProps {
  item: {
    name: string;
    rank: string[];
    career: string[];
    image: string;
  };
  className?: string;
}

const Item: React.FC<ItemProps> = ({ item, className }) => {
  return (
    <div className={cn(styles.item, className)}>
      <div className={styles.preview}>
        <img className={styles.image} src={item.image} alt={item.name} />
      </div>
      <div className={styles.name}>{item.name}</div>
      <ul>
        {item.rank.map((r, index) => (
          <li className={styles.rank} key={index}>
            {r}
          </li>
        ))}
      </ul>
      <ul>
        {item.career.map((c, index) => (
          <li className={styles.career} key={index}>
            {c}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Item;
