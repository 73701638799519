import cn from 'classnames';
import { FC, ReactNode } from 'react';
import styles from './Panel.module.sass';

interface PanelProps {
  className?: string;
  menu?: boolean;
  classBody?: string;
  children?: ReactNode;
  onSearch?: () => void;
  classButtonSearch?: string;
}

const Panel: FC<PanelProps> = ({ className, classBody, children }) => {
  return (
    <div className={cn(className, styles.panel)}>
      <div className={styles.background}></div>
      <div className={cn(styles.body, classBody)}>{children}</div>
    </div>
  );
};

export default Panel;
