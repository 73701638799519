import styled from 'styled-components';

import 'swiper/css';
import 'swiper/css/effect-cards';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import Arrow from '../assets/Arrow.svg';

import { Autoplay, EffectCards, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import Character, {
  characterIdToName,
} from '../../../../../components/Character';

const Carousel = () => {
  const slides = Array.from({ length: 7 }, (_, i) => i + 1).map((id) => (
    <SwiperSlide key={id}>
      <img src={Character(id)} alt={characterIdToName(id)} />
    </SwiperSlide>
  ));

  return (
    <Container>
      <Swiper
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
        }}
        pagination={{
          type: 'fraction',
        }}
        scrollbar={{
          draggable: true,
        }}
        navigation={true}
        effect={'cards'}
        grabCursor={true}
        modules={[EffectCards, Pagination, Navigation, Autoplay]}
        className="mySwiper"
      >
        {slides}
      </Swiper>
    </Container>
  );
};

const Container = styled.div`
  width: 25vw;
  height: 70vh;

  @media (max-width: 70em) {
    height: 60vh;
  }

  @media (max-width: 64em) {
    height: 50vh;
    width: 30vw;
  }

  @media (max-width: 48em) {
    height: 50vh;
    width: 40vw;
  }

  @media (max-width: 30em) {
    height: 45vh;
    width: 60vw;
  }

  .swiper {
    height: 100%;
    width: 100%;
    max-width: 472px;

    &-slide {
      width: auto;
      background-color: #eeedde;
      border-radius: 20px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        display: block;
        width: 60%;
        height: auto;
        object-fit: cover;
      }
      .small {
        width: 30%;
      }
    }

    &-pagination {
      color: #202020;
    }

    &-button-next {
      width: 4rem;
      right: 0;
      top: 60%;
      color: #202020;
      background-image: url(${Arrow});
      background-position: center;
      background-size: cover;

      &::after {
        display: none;
      }

      @media (max-width: 64em) {
        width: 3rem;
      }

      @media (max-width: 30em) {
        width: 2rem;
      }
    }

    &-button-prev {
      width: 4rem;
      left: 0;
      top: 60%;
      color: #202020;
      transform: rotate(180deg);
      background-image: url(${Arrow});
      background-position: center;
      background-size: cover;

      &::after {
        display: none;
      }

      @media (max-width: 64em) {
        width: 3rem;
      }

      @media (max-width: 30em) {
        width: 2rem;
      }
    }
  }
`;

export default Carousel;
