import { Auth } from 'aws-amplify';
import cn from 'classnames';
import React from 'react';
import ErrorBox from '../../../../components/ErrorBox';
import Loader from '../../../../components/Loader';
import TextInput from '../../../../components/TextInput';
import styles from './Entry.module.sass';

interface EntryProps {
  onSendCodeDone: (email: string) => void;
}

const Entry: React.FC<EntryProps> = ({ onSendCodeDone }) => {
  // the state for email
  const [email, setEmail] = React.useState('');

  // the state for register error message
  const [errorMsg, setErrorMsg] = React.useState('');

  // the state for loading while in login process
  const [isLoading, setIsLoading] = React.useState(false);

  // the handle key press
  const handleKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>,
    callback: () => void
  ) => {
    // callback on enter key press
    if (event.key === 'Enter' || event.key === 'NumpadEnter') {
      callback();
    }
  };

  // handle forgot password
  const handleForgotPassword = async () => {
    try {
      // 버튼이 disabled 상태여도 tab으로 접근해서 엔터로 클릭할 수 있기 때문에
      // validation 체크를 한번 더 해준다.
      if (!email) return;
      if (isLoading) return;
      setIsLoading(true);
      await Auth.forgotPassword(email);
      onSendCodeDone(email);
    } catch (error: unknown) {
      if (error instanceof Error) setErrorMsg(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.form}>
      {/* form title */}
      <div className={styles.formTitle}>Reset your password using email</div>

      {/* input for email */}
      <TextInput
        className={styles.field}
        placeholder="Email"
        name="email"
        type="email"
        icon="mail"
        required
        onChange={(e) => setEmail(e.target.value)}
        onKeyDown={(e) => handleKeyPress(e, () => handleForgotPassword())}
      />

      <ErrorBox message={errorMsg} onClose={() => setErrorMsg('')} />
      <button
        className={cn('button', styles.button, {
          disabled: !email,
        })}
        onClick={handleForgotPassword}
      >
        <Loader
          className={cn(styles.loader, { [styles.hide]: !isLoading })}
          white
        />
        <span>Send Code</span>
      </button>
    </div>
  );
};

export default Entry;
