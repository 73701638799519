import cn from 'classnames';
import React from 'react';
import { use100vh } from 'react-div-100vh';
import { Link } from 'react-router-dom';
import Image from '../../../components/Image';
import Code from './Code';
import Entry from './Entry';
import styles from './Reset.module.sass';

const Reset = () => {
  // the height of window
  const heightWindow = use100vh();

  // the state for email only use for code validator props
  const [email, setEmail] = React.useState('');

  // the state for entries or code validator visible
  const [isEntriesVisible, setIsEntriesVisible] = React.useState(true);

  // the handle done send code user callback
  // set value and change visible state
  const onSendCodeDone = (email: string) => {
    setEmail(email);
    setIsEntriesVisible(false);
  };

  return (
    <div
      className={styles.auth}
      style={{ minHeight: heightWindow ? `${heightWindow}px` : undefined }}
    >
      {/* link - go to sign up */}
      <div className={styles.topLink}>
        Don’t have an account? <Link to="/join">Join</Link>
      </div>

      <div className={styles.wrapper}>
        {/* header */}
        <div className={styles.header}>
          {/* logo */}
          <Link className={styles.logo} to="/">
            <Image
              src="/images/logo/symbol-dark.svg"
              srcDark="/images/logo/symbol-light.svg"
              alt="D:Bear"
            />
          </Link>
          {/* title */}
          <div className={cn('h2', styles.title)}>Reset</div>
        </div>

        {/* entry or code */}
        {isEntriesVisible ? (
          <Entry onSendCodeDone={onSendCodeDone} />
        ) : (
          <Code email={email} />
        )}
      </div>
    </div>
  );
};

export default Reset;
