import cn from 'classnames';
import Slider from 'react-slick';
import Icon from '../../../components/Icon';
import SlickArrow from '../../../components/SlickArrow/SlickArrow';
import styles from './Officers.module.sass';
import Item from './item';
import OfficerItems from './officer_items';

const Officers = () => {
  // the officer items
  const officerItems = OfficerItems();

  // slick settings
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    nextArrow: (
      <SlickArrow>
        <Icon name="arrow-next" width={24} height={24} />
      </SlickArrow>
    ),
    prevArrow: (
      <SlickArrow>
        <Icon name="arrow-prev" width={24} height={24} />
      </SlickArrow>
    ),
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 100000,
        settings: 'unslick',
      },
    ],
  };

  return (
    <div className={styles.officer}>
      <div className={cn('section', styles.section)}>
        <div className={cn('container', styles.container)}>
          <div className={styles.inner}>
            <div className={styles.head}>
              <h2 className={cn('h2', styles.title)}>
                The Board & Executive Officers
              </h2>
            </div>
            <div>
              <Slider
                className={cn('officer-slider', styles.slider)}
                {...settings}
              >
                {officerItems.map((x, index) => (
                  <Item className={styles.item} item={x} key={index} />
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Officers;
