import * as React from 'react';
import SvgProps from './SvgProps';

const SvgYoutube: React.FunctionComponent<SvgProps> = ({
  width = 24,
  height = 24,
  fill = 'currentColor',
}: SvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill={fill}
    viewBox="0 0 24 24"
  >
    <defs>
      <clipPath id="youtube_svg__a">
        <path d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
    <g clipPath="url(#youtube_svg__a)">
      <path
        fillRule="evenodd"
        d="M12 5a50.342 50.342 0 0 0-6.207.378 2.817 2.817 0 0 0-2.472 2.438A30.64 30.64 0 0 0 3 12a30.641 30.641 0 0 0 .321 4.184 2.817 2.817 0 0 0 2.472 2.437A50.349 50.349 0 0 0 12 19a50.35 50.35 0 0 0 6.207-.378 2.817 2.817 0 0 0 2.472-2.437A30.647 30.647 0 0 0 21 12a30.647 30.647 0 0 0-.321-4.184 2.817 2.817 0 0 0-2.472-2.437A50.344 50.344 0 0 0 12 5ZM5.545 3.394a4.817 4.817 0 0 0-4.2 4.143A32.626 32.626 0 0 0 1 12a32.625 32.625 0 0 0 .341 4.463 4.817 4.817 0 0 0 4.2 4.143A52.343 52.343 0 0 0 12 21a52.34 52.34 0 0 0 6.455-.394 4.817 4.817 0 0 0 4.2-4.143A32.621 32.621 0 0 0 23 12a32.622 32.622 0 0 0-.341-4.463 4.817 4.817 0 0 0-4.2-4.143 53.126 53.126 0 0 0-12.909 0Z"
      />
      <path d="m10.53 7.957 5.113 3.195a1 1 0 0 1 0 1.7l-5.113 3.2A1 1 0 0 1 9 15.204V8.803a1 1 0 0 1 1.53-.846Z" />
    </g>
  </svg>
);
export default SvgYoutube;
