const ShppingItems = () => [
  {
    title: 'Bunny & Towel Bundle',
    number: '1480',
    image: '/images/content/shopping/shopping-pic-1.webp',
  },
  {
    title: 'Bottle Set',
    number: '1120',
    image: '/images/content/shopping/shopping-pic-2.webp',
  },
  {
    title: 'Star Playmate',
    number: '777',
    image: '/images/content/shopping/shopping-pic-3.webp',
  },
  {
    title: 'Pacifier Duo',
    number: '1432',
    image: '/images/content/shopping/shopping-pic-4.webp',
  },
  {
    title: 'Cozy Socks',
    number: '1480',
    image: '/images/content/shopping/shopping-pic-5.webp',
  },
  {
    title: 'Bath Essentials',
    number: '180',
    image: '/images/content/shopping/shopping-pic-6.webp',
  },
  {
    title: 'Comfort Cushion',
    number: '1155',
    image: '/images/content/shopping/shopping-pic-7.webp',
  },
  {
    title: 'SnugFit Socks',
    number: '2220',
    image: '/images/content/shopping/shopping-pic-8.webp',
  },
  {
    title: 'Formula Feeder',
    number: '480',
    image: '/images/content/shopping/shopping-pic-9.webp',
  },
];

export default ShppingItems;
