import badge1 from './badge-1.svg';
import badge2 from './badge-2.svg';

const Badge = (id) => {
  switch (id) {
    case '1':
      return badge1;
    case '2':
    default:
      return badge2;
  }
};

export default Badge;
