import { ReactNode } from 'react';
import Footer from '../Footer';
import Header from '../Header';
import styles from './Page.module.sass';

type Props = {
  children: ReactNode | ReactNode[];
};

const Page = ({ children }: Props) => {
  return (
    <>
      <div className={styles.page}>
        <Header />
        <div className={styles.inner}>{children}</div>
        <Footer />
      </div>
    </>
  );
};

export default Page;
