import * as React from 'react';
import SvgProps from './SvgProps';

const SvgClose: React.FunctionComponent<SvgProps> = ({
  width = 24,
  height = 24,
  fill = 'currentColor',
}: SvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill={fill}
    viewBox="0 0 24 24"
  >
    <path
      fill="#B1B5C4"
      fillRule="evenodd"
      d="M10 20a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm-2 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM20 20a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm-2 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM2.106 2.553a1 1 0 0 1 1.341-.447l1.004.501A3 3 0 0 1 6.097 5.02l.048.526A.5.5 0 0 0 6.643 6h13.192a2.5 2.5 0 0 1 2.341 3.378l-1.753 4.675A3 3 0 0 1 17.614 16H7.826a3 3 0 0 1-2.987-2.728L4.105 5.2a1 1 0 0 0-.549-.804l-1.003-.502a1 1 0 0 1-.447-1.341ZM6.916 8a.5.5 0 0 0-.499.545l.414 4.546a1 1 0 0 0 .995.909h9.788a1 1 0 0 0 .936-.649l1.754-4.675A.5.5 0 0 0 19.836 8H6.915Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgClose;
