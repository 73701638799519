import { FC, ReactNode, useEffect } from 'react';

interface Props {
  currentSlide?: number;
  slideCount?: number;
  children: ReactNode;
}

const SlickArrow: FC<Props> = ({
  currentSlide = 0,
  slideCount = 1,
  children,
  ...props
}) => {
  useEffect(() => {
    if (currentSlide < 0) {
      console.warn(`Invalid currentSlide value ${currentSlide}. Setting to 0.`);
      // eslint-disable-next-line react-hooks/exhaustive-deps
      currentSlide = 0;
    } else if (currentSlide >= slideCount) {
      console.warn(
        `Invalid currentSlide value ${currentSlide}. Setting to ${
          slideCount - 1
        }.`
      );
      currentSlide = slideCount - 1;
    }
  }, [currentSlide, slideCount]);

  return <button {...props}>{children}</button>;
};

export default SlickArrow;
