import * as React from 'react';
import SvgProps from './SvgProps';

const SvgBulb: React.FunctionComponent<SvgProps> = ({
  width = 24,
  height = 24,
  fill = 'currentColor',
}: SvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill={fill}
    viewBox="0 0 24 24"
  >
    <path
      fill="#777E91"
      fillRule="evenodd"
      d="M10 18v3a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1v-3h2v3a3 3 0 0 1-3 3h-2a3 3 0 0 1-3-3v-3h2Z"
      clipRule="evenodd"
    />
    <path
      fill="#777E91"
      fillRule="evenodd"
      d="m15 13.285.75-.6a6 6 0 1 0-7.498 0l.748.6V16a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1v-2.715Zm2 .96V16a3 3 0 0 1-3 3h-4a3 3 0 0 1-3-3v-1.755a8 8 0 1 1 10 0Z"
      clipRule="evenodd"
    />
    <path
      fill="#777E91"
      d="M10.707 8.293a1 1 0 0 0-1.414 1.414L11 11.414V17h2v-5.586l1.707-1.707a1 1 0 0 0-1.414-1.414L12 9.586l-1.293-1.293Z"
    />
  </svg>
);
export default SvgBulb;
