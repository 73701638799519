import * as React from 'react';
import SvgProps from './SvgProps';

const SvgNFT: React.FunctionComponent<SvgProps> = ({
  width = 24,
  height = 24,
  fill = 'currentColor',
}: SvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill={fill}
    viewBox="0 0 24 24"
  >
    <g clipPath="url(#Line_svg__a)">
      <path d="M22.135 5.743 12.352.094a.703.703 0 0 0-.704 0L1.865 5.743a.703.703 0 0 0-.352.609v11.296c0 .252.135.484.352.61l9.783 5.648a.703.703 0 0 0 .704 0l9.783-5.649a.703.703 0 0 0 .351-.609V6.352a.703.703 0 0 0-.351-.61ZM20.5 16.783 12 22l-8.5-5.216V7L12 2l8.5 5v9.784Z" />
      <path d="M8.66 9.037a.703.703 0 0 0-.702.704v2.81l-1.284-3.08a.703.703 0 0 0-.649-.434h-.753a.703.703 0 0 0-.703.704v4.518c.034.932 1.372.932 1.406 0v-2.81l1.284 3.08c.109.263.365.434.649.434h.753a.703.703 0 0 0 .703-.704V9.741a.703.703 0 0 0-.703-.704ZM10.594 9.74v4.52c.034.931 1.372.93 1.406 0v-1.18h1.506c.932-.035.931-1.372 0-1.407H12v-1.23h2.26c.931-.034.93-1.371 0-1.406h-2.963a.703.703 0 0 0-.703.704ZM16.142 10.444h.803v3.815c.035.932 1.372.932 1.407 0v-3.815h.803c.932-.035.93-1.372 0-1.407h-3.013c-.932.035-.931 1.372 0 1.407Z" />
    </g>
    <defs>
      <clipPath id="Line_svg__a">
        <path d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgNFT;
