import { Auth } from 'aws-amplify';
import cn from 'classnames';
import React, { useCallback, useRef } from 'react';
import ErrorBox from '../../../../components/ErrorBox';
import Loader from '../../../../components/Loader';
import TextInput from '../../../../components/TextInput';
import styles from './Entry.module.sass';
import { checkConfirm, checkEmail, checkPassword } from './validate';

interface EntryProps {
  onRegisterDone: (email: string, password: string) => void;
}

const Entry: React.FC<EntryProps> = ({ onRegisterDone }) => {
  // the ref for input - password
  const inputPasswordRef = useRef<HTMLInputElement | null>(null);

  // the ref for input - confirm password
  const inputConfirmRef = useRef<HTMLInputElement | null>(null);

  // the state for email
  const [email, setEmail] = React.useState('');

  // the state for email validation result text
  const [emailValid, setEmailValid] = React.useState('');

  // the state for password
  const [password, setPassword] = React.useState('');

  // the state for password validation result text
  const [passwordValid, setPasswordValid] = React.useState('');

  // the state for confirm password
  const [confirm, setConfirm] = React.useState('');

  // the state for confirm password validation result text
  const [confirmValid, setConfirmValid] = React.useState('');

  // the state for register error message
  const [errorMsg, setErrorMsg] = React.useState('');

  // the state for loading while in login process
  const [isLoading, setIsLoading] = React.useState(false);

  // the handle key press
  const handleKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>,
    callback: () => void
  ) => {
    // callback on enter key press
    if (event.key === 'Enter' || event.key === 'NumpadEnter') {
      callback();
    }
  };

  // the handle go next input field focus
  const handleGoNextInput = (
    data: string,
    validCheckMsg: string,
    ref: React.MutableRefObject<HTMLInputElement | null>
  ) => {
    // data empty or validation error then do nothing
    if (!data || validCheckMsg) return;
    if (ref && ref.current) ref.current.focus();
  };

  // on input value change event - email
  const onChangeEmail = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const emailCurrent = e.target.value;
      setEmail(emailCurrent);
      setEmailValid(checkEmail(emailCurrent));
    },
    []
  );

  // on input value change event - password
  // password & confirm valid check
  const onChangePassword = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const passwordCurrent = e.target.value;
      setPassword(passwordCurrent);
      setPasswordValid(checkPassword(passwordCurrent));
      setConfirmValid(checkConfirm(passwordCurrent, confirm));
    },
    [confirm]
  );

  // on input value change event - confirm
  const onChangeConfirm = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const confirmCurrent = e.target.value;
      setConfirm(confirmCurrent);
      setConfirmValid(checkConfirm(password, confirmCurrent));
    },
    [password]
  );

  // the handle register
  const handleRegister = async () => {
    try {
      // 버튼이 disabled 상태여도 tab으로 접근해서 엔터로 클릭할 수 있기 때문에
      // validation 체크를 한번 더 해준다.
      if (emailValid || passwordValid || confirmValid) return;
      if (!email || !password || !confirm) return;
      if (isLoading) return;
      setIsLoading(true);
      await Auth.signUp({
        username: email,
        password: password,
        attributes: {
          email: email,
        },
      });
      onRegisterDone(email, password);
    } catch (error: unknown) {
      if (error instanceof Error) setErrorMsg(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.form}>
      {/* form title */}
      <div className={styles.formTitle}>Join with email address</div>

      {/* input for email */}
      <TextInput
        className={styles.field}
        placeholder="Email"
        name="email"
        type="email"
        icon="mail"
        required
        error={emailValid !== ''}
        onChange={onChangeEmail}
        onKeyDown={(e) =>
          handleKeyPress(e, () =>
            handleGoNextInput(email, emailValid, inputPasswordRef)
          )
        }
      />
      {/* validation check message for email */}
      <p className={cn(styles.invalid, { [styles.hide]: !emailValid })}>
        {emailValid}
      </p>

      {/* input for password */}
      <TextInput
        className={styles.field}
        placeholder="Password"
        name="password"
        type="password"
        icon="lock"
        required
        error={passwordValid !== ''}
        inputRef={inputPasswordRef}
        onChange={onChangePassword}
        onKeyDown={(e) =>
          handleKeyPress(e, () =>
            handleGoNextInput(password, passwordValid, inputConfirmRef)
          )
        }
      />
      {/* validation check message for password */}
      <p className={cn(styles.invalid, { [styles.hide]: !passwordValid })}>
        {passwordValid}
      </p>

      {/* input for confirm password */}
      <TextInput
        className={styles.field}
        placeholder="Confirm Password"
        name="password"
        type="password"
        icon="lock"
        required
        error={confirmValid !== ''}
        inputRef={inputConfirmRef}
        onChange={onChangeConfirm}
        onKeyDown={(e) => handleKeyPress(e, () => handleRegister())}
      />
      {/* validation check message for confirm password */}
      <p className={cn(styles.invalid, { [styles.hide]: !confirmValid })}>
        {confirmValid}
      </p>

      {/* error box for login error message */}
      <ErrorBox message={errorMsg} onClose={() => setErrorMsg('')} />

      {/* login button */}
      <button
        className={cn('button', styles.button, {
          disabled:
            !email ||
            !password ||
            !confirm ||
            emailValid ||
            passwordValid ||
            confirmValid,
        })}
        onClick={handleRegister}
      >
        <Loader
          className={cn(styles.loader, { [styles.hide]: !isLoading })}
          white
        />
        <span>Continue</span>
      </button>

      {/* notify some info */}
      <div className={styles.note}>
        By continuing, you are considered to have agreed to the D:Bear Service
        Terms and acknowledged that you have read the Privacy Policy.
      </div>
    </div>
  );
};

export default Entry;
