import cn from 'classnames';
import Icon from '../../../components/Icon';
import styles from './Whitepaper.module.sass';

const items = [
  {
    language: '한국어',
    url: 'https://dbear-universe-public-storage.s3.ap-northeast-2.amazonaws.com/dbear_korean.pdf',
  },
  {
    language: 'English',
    url: 'https://dbear-universe-public-storage.s3.ap-northeast-2.amazonaws.com/dbear_english.pdf',
  },
  {
    language: '中国人',
    url: 'https://dbear-universe-public-storage.s3.ap-northeast-2.amazonaws.com/dbear_chinese.pdf',
  },
];

const Whitepaper = () => {
  return (
    <div className={styles.whitepaper}>
      <div className={cn('section', styles.section)}>
        <div className={cn('container', styles.container)}>
          <div className={styles.wrap}>
            <h2 className={cn('h2', styles.title)}>Whitepaper</h2>
            <div className={styles.subtitle}>Decentralize Enchanted Bear</div>
          </div>

          <div className={styles.body}>
            <div className={styles.list}>
              {items.map((x, index) => (
                <a
                  href={x.url}
                  key={index}
                  className={styles.item}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className={styles.item} key={index}>
                    <div className={styles.preview}>
                      <Icon name="hexagon" width={232} height={253} />
                      <div className={styles.overlay}>
                        <Icon name="pdf" width={106} height={118} />
                        <div className={styles.language}>{x.language}</div>
                      </div>
                    </div>
                  </div>
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Whitepaper;
